<template>
  <div>
    <inertia-link
      v-if="!external"
      v-bind="$attrs"
      :preserve-state="preserveState"
      preserve-scroll
      class="flex items-center w-full text-lg uppercase justify-start"
    >
      <slot />
    </inertia-link>
    <a
      v-if="external"
      v-bind="$attrs"
      class="flex items-center w-full text-lg uppercase justify-start"
    >
      <slot />
    </a>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    preserveState: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
