<template>
  <div class="mt-2">
    <h1 class="text-b2 font-extrabold text-prime-green mb-2">
      {{ $t("components.sla.sla") }}
    </h1>
    <table>
      <tr>
        <td class="text-b3 text-prime-blue-dark pr-3.5 pb-1">
          {{ $t("components.slaContent.status").toUpperCase() }}:
        </td>
        <td
          v-if="isSlaActive(slaContent)"
          class="text-b3 text-prime-grey-darkest align-top"
        >
          {{ $t("components.slaContent.statusActiveShort").toUpperCase() }}
          <inline-svg
            :src="require('@/assets/circle.svg')"
            class="w-2.5 inline-block fill-current mx-2 align-baseline text-indicator-green"
          />
        </td>
        <td
          v-if="!isSlaActive(slaContent)"
          class="text-b3 text-prime-grey-darkest align-top"
        >
          {{ $t("components.slaContent.statusInactiveShort").toUpperCase() }}
          <inline-svg
            :src="require('@/assets/circle.svg')"
            class="w-2.5 inline-block fill-current mx-2 align-baseline text-prime-red-dark"
          />
        </td>
      </tr>
      <tr
        v-if="slaContent.startsAt && new Date(slaContent.startsAt) > new Date()"
      >
        <td class="text-b3 text-green-2 pr-3.5 pb-1">
          {{ $t("components.slaContent.startsAt").toUpperCase() }}:
        </td>
        <td class="text-b3 text-prime-grey-darkest align-top">
          {{ formatDate(slaContent.startsAt) }}
        </td>
      </tr>
      <tr>
        <td class="text-b3 text-green-2 pr-3.5 pb-1">
          {{ $t("components.slaContent.expires").toUpperCase() }}:
        </td>
        <td class="text-b3 text-prime-grey-darkest align-top">
          {{ formatDate(slaContent.endsAt) }}
        </td>
      </tr>
    </table>
    <div v-if="slaContent.description" class="mb-4">
      <p class="text-b3 text-prime-blue-dark pb-1">
        {{ $t("common.fields.description").toUpperCase() }}:
      </p>
      <div
        class="text-b3 text-prime-grey-darkest"
        v-html="slaContent.description"
      ></div>
    </div>
  </div>

  <div v-if="slaContent.files && slaContent.files.length > 0">
    <div class="flex flex-col w-full font-bold text-prime-green mb-4">
      <h1 class="uppercase">{{ $t("components.slaContent.files") }}</h1>
      <div class="flex flex-wrap items-start w-full">
        <template v-for="file in addIndexForImageViewer(slaContent.files)">
          <div
            v-if="file.imageIndex != null"
            :key="file.id"
            class="flex flex-col items-center w-1/2 p-4 sm:w-28 has-tooltip"
          >
            <img
              :src="
                route('api.files.content', {
                  id: file.id,
                  filename: file.originalName,
                  thumbnail: !!file.thumbnailUrl
                })
              "
              class="fill-current text-green-2 h-16 font-black inline-block cursor-pointer w-16 h-16"
              @click="
                viewImageObject(addIndexForImageViewer(slaContent.files), file)
              "
            />
            <span
              class="tooltip rounded shadow-xl p-1 bg-black text-white -mt-8"
              >{{ file.originalName }}</span
            >
          </div>
          <file
            v-else
            :key="file.id"
            :file="file"
            hide-file-name
            show-tooltip
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { isSlaActive } from "@/util/slaHelpers";
import File from "@/components/File.vue";
import "@/assets/css/viewer.css";
import { api as viewerApi } from "v-viewer";

export default {
  components: { File },

  props: ["slaContent"],

  methods: {
    isSlaActive,
    formatDate(date) {
      if (!date) return this.$t("common.placeholders.unknown");

      const options = { day: "numeric", month: "long", year: "numeric" };

      return new Date(date).toLocaleDateString(this.$i18n.locale, options);
    },
    addIndexForImageViewer(files) {
      let index = 0;
      return files.map((obj) => {
        let newObj = {
          ...obj,
          imageIndex: this.isImage(obj) ? index : null,
          url: this.getRoute(obj),
          alt: obj.originalName,
        };
        index++;
        return newObj;
      });
    },
    getRoute(file) {
      return this.route('api.files.content', {
        id: file.id,
        filename: file.originalName
      })
    },
    isImage(file) {
      var extension = file.extension.toLowerCase();
      if ([".png", ".jpg", ".jpeg"].includes(extension)) {
        return true;
      }
      return false;
    },
    viewImageObject(filesWithImageIndex, file) {
      let imageArray = filesWithImageIndex.filter((x) => x.imageIndex != null);
      viewerApi({
        options: {
          toolbar: true,
          url: "url",
          initialViewIndex: file.imageIndex,
          title: (image) => {
            return image.alt;
          },
        },
        images: imageArray,
      });
    },
  },
};
</script>
