<template>
  <div>
    <div
      class="bg-brand3 flex flex-col p-2 w-full uppercase text-center h-52 pb-8"
    >
      <div v-t="'pages.home.welcome'" class="text-sm tracking-widest" />
      <img
        :src="
          route('api.files.content', {
            id: organization.logoFile?.id,
            filename: organization.logoFile?.originalName,
          })
        "
        class="pt-6 h-28 self-center"
      />
      <div class="text-xl pt-2">{{ organization.name }}</div>
      <div v-if="locationFirstSla">
        <i18n-t
          :keypath="
            isLocationFirstSlaActive()
              ? 'components.sla.statusActive'
              : 'components.sla.statusInactive'
          "
          tag="div"
          class="text-xs inline-block mr-2"
        >
          {{ formatDate(locationFirstSla.endsAt) }}
        </i18n-t>
        <inline-svg
          :src="require('@/assets/circle.svg')"
          class="w-4 inline-block fill-current"
          :class="
            isLocationFirstSlaActive() ? 'text-green-600' : 'text-red-600'
          "
        />
      </div>
    </div>
    <div>
      <menu-group
        bg="bg-brand8"
        :icon="require('@/assets/new-support-ticket.svg')"
        :is-active="false"
        :active-link="route('home')"
        :inactive-link="route('support-cases.create')"
      >
        <template #text>
          {{ $t("pages.home.newsupportcase") }}
        </template>
      </menu-group>
      <menu-group
        bg="bg-brand7"
        :icon="require('@/assets/stores.svg')"
        :is-active="!!location"
        :active-link="route('home')"
        :inactive-link="route('my-location')"
      >
        <template #text>
          {{ $t("pages.home.mylocation") }}
        </template>
        <template #content>
          <div
            class="bg-gray-200 pr-6 pl-4 pt-4 pb-8 space-y-3 text-gray-500 flex-auto"
          >
            <div>
              <div class="border-b pb-4 border-gray-300">
                <h1 class="text-xl text-brand7 font-medium mb-1">
                  {{ $t("pages.mylocation.address") }}
                </h1>
                <data-line
                  :heading="$t('pages.mylocation.addressLine1')"
                  :value="
                    location.addressLine1 || $t('common.placeholders.unknown')
                  "
                  right-aligned
                />
                <data-line
                  v-if="location.addressLine2"
                  :heading="$t('pages.mylocation.addressLine2')"
                  :value="location.addressLine2"
                  right-aligned
                />
                <data-line
                  :heading="$t('pages.mylocation.postalCode')"
                  :value="
                    location.postalCode || $t('common.placeholders.unknown')
                  "
                  right-aligned
                />
                <data-line
                  :heading="$t('pages.mylocation.city')"
                  :value="location.city || $t('common.placeholders.unknown')"
                  right-aligned
                />
                <data-line
                  v-if="location.state"
                  :heading="$t('pages.mylocation.state')"
                  :value="location.state"
                  right-aligned
                />
                <data-line
                  :heading="$t('pages.mylocation.country')"
                  :value="location.country || $t('common.placeholders.unknown')"
                  right-aligned
                />
              </div>
              <div
                v-if="
                  location.slaContents.length ||
                  location.contents.length ||
                  location.locationType.contents.length
                "
                class="mt-2"
              >
                <h1 class="text-xl text-brand7 font-medium">
                  {{ $t("pages.mylocation.availableContent") }}
                </h1>
                <sla-contents
                  v-if="location.slaContents"
                  :sla-contents="location.slaContents"
                />
                <contents
                  v-if="location.contents"
                  :contents="location.contents"
                />
                <contents
                  v-if="location.locationType.contents"
                  :contents="location.locationType.contents"
                />
              </div>
            </div>
            <div class="mt-6">
              <a
                class="text-white font-bold py-2 uppercase bg-brand7 w-full block text-center"
                :href="
                  route('document-requests.create', { locationId: location.id })
                "
              >
                {{ $t("pages.home.requestDocuments") }}
              </a>
            </div>
          </div>
        </template>
      </menu-group>
      <menu-group
        bg="bg-brand6"
        :icon="require('@/assets/stores.svg')"
        :is-active="isMySolutionsExpanded()"
        :active-link="route('home')"
        :inactive-link="route('my-solutions')"
      >
        <template #text> {{ $t("pages.home.mysolutions") }} </template>
        <template #content>
          <menu-group
            v-for="(item, index) in solutions"
            :key="item.id"
            :bg="index % 2 == 0 ? 'bg-brand4' : 'bg-brand5'"
            :is-active="isSolutionActive(item)"
            :active-link="route('my-solutions')"
            :inactive-link="route('my-solutions.show', item.id)"
            :only="isSolutionActive(item) ? [] : ['solution']"
          >
            <template #text>
              <div class="pl-12">{{ item.solutionType.name }}</div></template
            >
            <template #content>
              <div
                class="bg-gray-200 pr-6 pl-4 pt-4 pb-8 space-y-4 text-gray-500 flex-auto"
              >
                <div
                  v-if="
                    solution.slaContents.length ||
                    solution.contents.length ||
                    solution.solutionType.contents.length
                  "
                >
                  <h1 class="text-xl text-brand7 font-medium">
                    {{ $t("pages.mylocation.availableContent") }}
                  </h1>
                  <sla-contents
                    v-if="solution.slaContents"
                    :sla-contents="solution.slaContents"
                  />
                  <contents
                    v-if="solution.contents"
                    :contents="solution.contents"
                  />
                  <contents
                    v-if="solution.solutionType.contents"
                    :contents="solution.solutionType.contents"
                  />
                </div>
                <div class="mt-6">
                  <a
                    class="text-white font-bold py-2 uppercase bg-brand7 w-full block text-center"
                    :href="
                      route('document-requests.create', {
                        solutionId: solution.id,
                      })
                    "
                  >
                    {{ $t("pages.home.requestDocuments") }}
                  </a>
                </div>
                <div class="mt-6">
                  <a
                    class="text-white font-bold py-2 uppercase bg-brand7 w-full block text-center"
                    :href="
                      route('support-cases.create', { solutionId: solution.id })
                    "
                  >
                    {{ $t("pages.home.newsupportcase") }}
                  </a>
                </div>
              </div>
            </template>
          </menu-group>
        </template>
      </menu-group>
      <menu-group
        bg="bg-brand5"
        :icon="require('@/assets/support-ticket.svg')"
        :is-active="route().current('my-support-cases')"
        :active-link="route('home')"
        :inactive-link="route('my-support-cases')"
      >
        <template #text> {{ $t("pages.home.mysupportcases") }} </template>
        <template #content>
          <support-cases
            :items="supportCases"
            :statuses="statuses"
            :selected-status="status"
            index-route="my-support-cases"
            show-route="support-cases.show"
          />
        </template>
      </menu-group>
      <menu-group
        bg="bg-brand6"
        :icon="require('@/assets/file-document-outline.svg')"
        :is-active="route().current('organizational-documents')"
        :active-link="route('home')"
        :inactive-link="route('organizational-documents')"
      >
        <template #text>
          {{ $t("pages.home.organizationalDocuments") }}
        </template>
        <template #content>
          <div
            class="bg-gray-200 pr-6 pl-4 pt-4 pb-8 space-y-4 text-gray-500 flex-auto"
          >
            <div>
              <contents
                v-if="organizationContents && organizationContents.length"
                :contents="organizationContents"
              />
              <h1 v-else class="text-xl text-brand7 font-medium">
                {{ $t("common.placeholders.documents.noneAvailable") }}
              </h1>
            </div>
          </div>
        </template>
      </menu-group>
    </div>
  </div>
</template>
<script>
import MenuGroup from "../components/MenuGroup.vue";
import Contents from "../components/Contents.vue";
import SlaContents from "../components/SlaContents.vue";
import SupportCases from "../components/SupportCases.vue";
import DataLine from "../components/DataLine.vue";

import { isSlaActive } from "@/util/slaHelpers";

export default {
  components: { MenuGroup, Contents, SlaContents, SupportCases, DataLine },
  props: [
    "location",
    "solutions",
    "solution",
    "supportCases",
    "statuses",
    "status",
    "organizationContents",
  ],
  data() {
    return {
      organization: this.$page.props.auth.organization,
      locationFirstSla: this.$page.props.auth.locationFirstSla,
    };
  },
  methods: {
    isLocationFirstSlaActive() {
      let slaContent = this.locationFirstSla;

      if (!slaContent) return false;

      return this.isSlaActive(slaContent);
    },
    isSlaActive,
    formatDate(date) {
      if (!date) return this.$t("common.placeholders.unknown");

      return new Date(date).toLocaleDateString();
    },
    isSolutionActive(item) {
      return item.id == this.solution?.id;
    },
    isMySolutionsExpanded() {
      return (
        this.route().current("my-solutions") ||
        this.route().current("my-solutions.show")
      );
    },
  },
};
</script>
