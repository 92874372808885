import accountSettings from "./accountSettings.json";
import documentRequest from "./documentRequest.json";
import executive from "./executive.json";
import executiveLocations from "./executiveLocations.json";
import exportLocations from "./exportLocations.json";
import featureRequest from "./featureRequest.json";
import forgotPassword from "./forgotPassword.json";
import forgotPasswordConfirmation from "./forgotPasswordConfirmation.json";
import home from "./home.json";
import login from "./login.json";
import mylocation from "./mylocation.json";
import newsupportcase from "./newsupportcase.json";
import resetPassword from "./resetPassword.json";
import resetPasswordConfirmation from "./resetPasswordConfirmation.json";
import supportcase from "./supportcase.json";
import supportMap from "./supportMap.json";

export default {
  accountSettings,
  documentRequest,
  executive,
  executiveLocations,
  exportLocations,
  featureRequest,
  forgotPassword,
  forgotPasswordConfirmation,
  home,
  login,
  mylocation,
  newsupportcase,
  resetPassword,
  resetPasswordConfirmation,
  supportcase,
  supportMap
};