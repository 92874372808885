<template>
  <support-layout :menuIndex="1">
    <div class="relative flex flex-col flex-grow">
      <div class="flex-1 flex flex-col flex-grow">
        <multiselect
          mode="single"
          v-model="selectedSupportType"
          :options="supportTypes"
          :closeOnSelect="true"
          :searchable="true"
          label="name"
          value-prop="id"
          :canClear="false"
          :canDeselect="false"
          :object="true"
          :placeholder="$t('pages.newsupportcase.chooselocation')"
          @select="selectRequestType"
          class="text-prime-green uppercase mb-4 font-black"
          :classes="multiselectClasses"
        />
        <multiselect
          mode="single"
          v-model="selectedLocation"
          :options="locations"
          :closeOnSelect="true"
          :searchable="true"
          label="name"
          value-prop="id"
          :canClear="false"
          :canDeselect="false"
          :object="true"
          :placeholder="$t('pages.newsupportcase.chooselocation')"
          @select="selectLocation"
          class="text-prime-green uppercase mb-4 font-black"
          :classes="multiselectClasses"
        />
        <multiselect
          mode="single"
          v-model="selectedSolution"
          :options="computedSolutions"
          :closeOnSelect="true"
          :searchable="true"
          label="name"
          value-prop="id"
          :canClear="false"
          :canDeselect="false"
          :object="true"
          :placeholder="$t('pages.newsupportcase.choosesolution')"
          @select="selectSolution"
          class="text-prime-green uppercase mb-4 font-black"
          :classes="multiselectClasses"
        />
        <multiselect
          mode="single"
          v-model="form.documentType"
          :options="computedDocumentTypes"
          :closeOnSelect="true"
          :searchable="true"
          label="label"
          value-prop="documentType"
          :canClear="false"
          :canDeselect="false"
          :placeholder="$t('pages.documentRequest.documentType')"
          class="text-prime-green uppercase mb-4 font-black"
          :classes="multiselectClasses"
        />
        <textarea
          v-model="form.message"
          :placeholder="$t('pages.documentRequest.messagePlaceholder')"
          rows="3"
          class="outline-none text-prime-black h-40 w-full placeholder-prime-grey-dark border-prime-grey-dark px-4 py-2 resize-none rounded flex-grow mb-4"
        />
        <input
          type="email"
          class="outline-none text-prime-black placeholder-prime-grey-dark border-prime-grey-dark px-4 py-2 w-full rounded mb-4"
          :placeholder="$t('pages.documentRequest.emailPlaceholder')"
          v-model="form.email"
        />
        <button
          class="mt-4 py-2 h-10 uppercase text-white w-full self-end base-button sm:w-1/5"
          :disabled="!isFormFilled"
          @click="submit"
        >
          {{ $t("pages.newsupportcase.create") }}
        </button>
      </div>
    </div>
  </support-layout>
</template>
<script>
import { computed, defineComponent } from "vue";
import { useForm } from "@inertiajs/inertia-vue3";
import { objectToFormData } from "@/util/formData";
import { pascalToSpaces } from "@/util/text";
import route from "@/vendor/ziggy";
import SupportLayout from "@/Pages/SupportLayout.vue";
import Multiselect from "@vueform/multiselect";
import "@/assets/scss/multiselect-tailwind.scss";
import multiselectClasses from "@/util/multiselectClasses.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    SupportLayout,
    Multiselect,
  },
  props: {
    documentTypes: Array,
    email: String,
    locations: Array,
    locationId: String,
    solutions: Array,
    solutionId: String,
  },
  setup(props) {
    const form = useForm({
      documentType: props.documentTypes[0],
      email: props.email,
      locationId: props.locationId,
      solutionId: props.solutionId,
      message: null,
    });
    const isFormFilled = computed(
      () => !!form.documentType && !!form.email && !!form.message
    );

    const submit = () => {
      form
        .transform((data) => objectToFormData(data))
        .post(route("document-requests.store"));
    };

    const { t } = useI18n();

    let supportTypes = [
      {
        id: 0,
        name: t("common.requests.support"),
        route: "support-cases.create",
      },
      {
        id: 1,
        name: t("common.requests.document"),
        route: "document-requests.create",
      },
      {
        id: 2,
        name: t("common.requests.feature"),
        route: "feature-requests.create",
      },
    ];

    return {
      supportTypes: supportTypes,
      selectedSupportType: supportTypes.find((st) => st.id === 1),
      selectedLocation: props.locations?.find((l) => l.id === props.locationId),
      selectedSolution: props.solutions?.find((s) => s.id === props.solutionId),
      pascalToSpaces,
      form,
      isFormFilled,
      submit,
      multiselectClasses,
    };
  },
  methods: {
    selectRequestType(type) {
      this.$inertia.visit(
        route(type.route, {
          locationId: this.form.locationId,
          solutionId: this.form.solutionId,
        }),
        { replace: true }
      );
    },
    selectLocation(location) {
      this.$inertia.visit(
        route("document-requests.create", { locationId: location.id }),
        { replace: true }
      );
    },
    selectSolution(solution) {
      this.$inertia.visit(
        route("document-requests.create", {
          locationId: this.form.locationId,
          solutionId: solution.id,
        }),
        { replace: true }
      );
    },
  },
  computed: {
    computedSolutions() {
      return [
        ...this.solutions,
        { name: this.$t("common.fields.solution.other"), id: null },
      ];
    },
    selectedLocationName() {
      return this.locations?.find((s) => s.id === this.form.locationId)?.name;
    },
    selectedSolutionName() {
      return this.solutions?.find((s) => s.id === this.form.solutionId)?.name;
    },
    computedDocumentTypes() {
      return this.documentTypes.map((x) => ({
        documentType: x,
        label: this.pascalToSpaces(x),
      }));
    },
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
