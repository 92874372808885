<template>
  <div>
    <menu-group
      v-for="(item, index) in items"
      :key="item.id"
      :bg="index % 2 == 0 ? 'bg-brand3' : 'bg-brand2'"
      :is-active="isSolutionActive(item)"
      :active-link="
        route('executive.solutions', {
          solutionTypeId: item.solutionTypeId,
          country: item.location.country,
        })
      "
      :inactive-link="route('executive.solutions.show', item.id)"
      :only="isSolutionActive(item) ? [] : ['solution']"
    >
      <template #text>
        <div class="pl-16 normal-case">
          {{
            !item.location.city
              ? $t("common.placeholders.city.unknown")
              : item.location.city
          }}
          /
          {{
            !item.location.addressLine1
              ? $t("common.placeholders.street.unknown")
              : item.location.addressLine1
          }}
        </div>
      </template>
      <template #content>
        <solution :item="selected" />
      </template>
    </menu-group>
  </div>
</template>
<script>
import MenuGroup from "../components/MenuGroup.vue";
import Solution from "../components/Solution.vue";

export default {
  components: { MenuGroup, Solution },
  props: ["items", "selected"],
  methods: {
    isSolutionActive(item) {
      return this.selected && item.id == this.selected.id;
    },
  },
};
</script>
