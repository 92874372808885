<template>
  <div>
    <div
      class="bg-brand3 flex flex-col p-2 w-full uppercase text-center h-52 pb-8"
    >
      <div v-t="'pages.home.welcome'" class="text-sm tracking-widest" />
      <img
        :src="
          route('api.files.content', {
            id: organization.logoFile?.id,
            filename: organization.logoFile?.originalName,
          })
        "
        class="pt-6 h-28 self-center"
      />
      <div class="text-xl pt-2">{{ organization.name }}</div>
    </div>
    <menu-group
      bg="bg-brand8"
      :icon="require('@/assets/new-support-ticket.svg')"
      :is-active="false"
      :active-link="route('executive.home')"
      :inactive-link="route('support-cases.create')"
    >
      <template #text>
        {{ $t("pages.home.newsupportcase") }}
      </template>
    </menu-group>
    <menu-group
      bg="bg-brand7"
      :icon="require('@/assets/stores.svg')"
      :is-active="!!locationTypes"
      :active-link="route('executive.home')"
      :inactive-link="route('executive.locations')"
    >
      <template #text>
        {{ $t("pages.executive.locations") }}
      </template>
      <template #content>
        <menu-group
          v-for="locationType in locationTypes"
          :key="locationType.id"
          bg="bg-brand6"
          :is-active="locationType.id === locationTypeId"
          :active-link="route('executive.locations')"
          :inactive-link="
            route('executive.locations', { locationTypeId: locationType.id })
          "
        >
          <template #text>
            <div class="pl-12">{{ locationType.name }}</div>
          </template>
          <template #content>
            <menu-group
              v-for="country in countries"
              :key="country"
              bg="bg-brand5"
              :is-active="country === selectedCountry"
              :active-link="route('executive.locations', { locationTypeId })"
              :inactive-link="
                route('executive.locations', { locationTypeId, country })
              "
            >
              <template #text>
                <div class="pl-14">
                  {{
                    country === "country.unknown"
                      ? $t(`common.placeholders.${country}`)
                      : country
                  }}
                </div>
              </template>
              <template #content>
                <locations
                  :items="locations"
                  :solutions="solutions"
                  :selected="location"
                  :selectedSolution="solution"
                />
              </template>
            </menu-group>
          </template>
        </menu-group>
      </template>
    </menu-group>
    <menu-group
      bg="bg-brand6"
      :icon="require('@/assets/stores.svg')"
      :is-active="!!solutionTypes"
      :active-link="route('executive.home')"
      :inactive-link="route('executive.solutions')"
    >
      <template #text> {{ $t("pages.executive.solutions") }} </template>
      <template #content>
        <menu-group
          v-for="solutionType in solutionTypes"
          :key="solutionType.id"
          bg="bg-brand5"
          :is-active="solutionType.id === solutionTypeId"
          :active-link="route('executive.solutions')"
          :inactive-link="
            route('executive.solutions', { solutionTypeId: solutionType.id })
          "
        >
          <template #text>
            <div class="pl-12">{{ solutionType.name }}</div>
          </template>
          <template #content>
            <menu-group
              v-for="country in countries"
              :key="country"
              bg="bg-brand4"
              :is-active="country === selectedCountry"
              :active-link="route('executive.solutions', { solutionTypeId })"
              :inactive-link="
                route('executive.solutions', { solutionTypeId, country })
              "
            >
              <template #text>
                <div class="pl-14">
                  {{
                    country === "country.unknown"
                      ? $t(`common.placeholders.${country}`)
                      : country
                  }}
                </div>
              </template>
              <template #content>
                <solutions :items="solutions" :selected="solution" />
              </template>
            </menu-group>
          </template>
        </menu-group>
      </template>
    </menu-group>

    <menu-group
      bg="bg-brand5"
      :icon="require('@/assets/support-ticket.svg')"
      :is-active="route().current('executive.support-cases')"
      :active-link="route('executive.home')"
      :inactive-link="route('executive.support-cases')"
      :only="[]"
    >
      <template #text> {{ $t("pages.executive.supportCases") }} </template>
      <template #content>
        <support-cases
          :statuses="statuses"
          :items="supportCases"
          :selected-status="status"
          index-route="executive.support-cases"
          show-route="support-cases.show"
        />
        <menu-item
          class="bg-brand5"
          :href="route('executive.support-cases.map')"
        >
          <inline-svg
            :src="require('@/assets/stores.svg')"
            class="fill-current text-white h-8 mr-3"
          />
          Show world map
        </menu-item>
      </template>
    </menu-group>
    <menu-group
      bg="bg-brand4"
      :icon="require('@/assets/file-document-outline.svg')"
      :is-active="route().current('executive.organizational-documents')"
      :active-link="route('home')"
      :inactive-link="route('executive.organizational-documents')"
    >
      <template #text>
        {{ $t("pages.home.organizationalDocuments") }}
      </template>
      <template #content>
        <div
          class="bg-gray-200 pr-6 pl-4 pt-4 pb-8 space-y-4 text-gray-500 flex-auto"
        >
          <div>
            <contents
              v-if="organizationContents && organizationContents.length"
              :contents="organizationContents"
            />
            <h1 v-else class="text-xl text-brand7 font-medium">
              {{ $t("common.placeholders.documents.noneAvailable") }}
            </h1>
          </div>
        </div>
      </template>
    </menu-group>
    <menu-group
      bg="bg-brand3"
      :icon="require('@/assets/file-excel-outline.svg')"
      :is-active="false"
      :active-link="route('executive.home')"
      :inactive-link="route('executive.locations.export.index')"
    >
      <template #text> {{ $t("pages.home.exportLocations") }} </template>
    </menu-group>
  </div>
</template>
<script>
import MenuGroup from "../components/MenuGroup.vue";
import Contents from "../components/Contents.vue";
import Solutions from "../components/Solutions.vue";
import SupportCases from "../components/SupportCases.vue";
import Locations from "../components/Locations.vue";
import MenuItem from "../components/MenuItem.vue";

export default {
  components: {
    MenuGroup,
    Solutions,
    Locations,
    SupportCases,
    MenuItem,
    Contents,
  },
  props: [
    "locations",
    "location",
    "locationTypes",
    "locationTypeId",

    "solutions",
    "solution",
    "solutionTypes",
    "solutionTypeId",

    "countries",
    "selectedCountry",

    "statuses",
    "status",
    "supportCases",

    "organizationContents",
  ],
  data() {
    return {
      organization: this.$page.props.auth.organization,
    };
  },
};
</script>
