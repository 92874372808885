<template>
  <div class="flex leading-5 space-x-2">
    <span class="break-words min-w-1/2">
      {{ heading }}
    </span>
    <span class="min-w-1/2 break-all whitespace-pre-line" :class="{'text-right': rightAligned}">
      {{ value }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    heading: String,
    value: String,
    rightAligned: Boolean
  },
};
</script>
