<template>
  <div class="pb-10">
    <menu-item
      v-for="(item, index) in availableItems"
      :key="index"
      :href="item.href"
      class="mb-4"
      :external="item.external"
      :method="item.method"
      :preserve-state="false"
      :as="item.method ? 'button' : 'a'"
    >
      <v-button type="submit">{{ item.label }}</v-button>
    </menu-item>
    <multiselect
      v-if="availableOrganizations.length > 1"
      mode="single"
      v-model="selectedOrganization"
      :options="availableOrganizations"
      :closeOnSelect="true"
      :searchable="true"
      label="name"
      value-prop="id"
      :canClear="false"
      :canDeselect="false"
      :object="true"
      :placeholder="$t('components.slideMenu.changeOrganization')"
      @select="selectOrganization"
      class="text-prime-green uppercase mb-4 font-black"
      :classes="multiselectClasses"
    />
  </div>
</template>
<script>
import MenuItem from "../components/SlideMenuItem";
import Multiselect from "@vueform/multiselect";
import "@/assets/scss/multiselect-tailwind.scss";
import multiselectClasses from "@/util/multiselectClasses.js";

export default {
  components: {
    MenuItem,
    Multiselect,
  },
  data() {
    return {
      multiselectClasses,
      selectedOrganization: this.$page.props.auth.organization,
      items: [
        {
          icon: require("@/assets/smart-office.svg"),
          label: this.$t("components.menu.home"),
          href: this.route("home"),
        },
        {
          icon: require("@/assets/speech-bubble.svg"),
          label: this.$t("components.slideMenu.export"),
          href: this.route("executive.export.support"),
          permission: "Export",
        },
        {
          icon: require("@/assets/speech-bubble.svg"),
          label: this.$t("components.slideMenu.orgDocuments"),
          href: this.route("executive.organizational-documents"),
          external: true,
          permission: "OrganizationalDocuments",
        },
        {
          icon: require("@/assets/cog.svg"),
          label: this.$t("components.menu.accountSettings"),
          href: this.route("account-settings.show"),
        },
        {
          icon: require("@/assets/speech-bubble.svg"),
          label: this.$t("components.menu.contactUs"),
          href: `mailto:${process.env.VUE_APP_CONTACT_EMAIL}`,
          external: true,
        },
        {
          icon: require("@/assets/power.svg"),
          label: this.$t("components.menu.logout"),
          method: "post",
          href: this.route("logout"),
        },
      ],
    };
  },
  methods: {
    selectOrganization() {
      fetch(
        this.route("user-organization.update", {
          organizationId: this.selectedOrganization.id,
        }),
        {
          method: "PUT",
        }
      ).then(() => {
        this.$inertia.get(this.route("home"));
      });
    },
  },
  computed: {
    availableItems() {
      let items = this.items.filter(
        (i) =>
          !i.permission || this.user.claims.some((x) => x.type == i.permission)
      );
      return items;
    },
    availableOrganizations() {
      return this.$page.props.auth.organizations;
    },
    user() {
      return this.$page.props.auth.user;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
