<template>
  <div class="flex justify-center flex-col my-auto flex-grow">
    <h1 class="text-xl font-bold text-center uppercase text-white">
      {{ $t("pages.home.welcome") }}
    </h1>
    <form class="pb-6" @submit.prevent="form.post(url)">
      <v-input
        type="email"
        v-model="form.email"
        :placeholder="$t('components.form.enterEmail')"
        :error-message="form.errors.email"
        class="mt-8 rounded-sm h-14 placeholder-prime_grey_dark px-5"
      />
      <v-input
        type="password"
        v-model="form.password"
        :placeholder="$t('components.form.enterPassword')"
        :error-message="form.errors.password"
        class="mt-8 rounded-sm h-14 placeholder-prime_grey_dark px-5"
      />
      <div
        v-if="!form.processing && $page.props.flash.error"
        class="text-sm p-2 bg-error mt-4 rounded text-white"
      >
        {{ $page.props.flash.error }}
      </div>
      <v-button
        type="submit"
        class="mt-8"
        :loading="form.processing"
        :disabled="form.processing"
        >{{ $t("pages.login.submitButton") }}</v-button
      >
      <label
        class="tracking-wide text-green-2 text-sm block mt-6 w-max flex items-center font-bold"
      >
        <input
          class="text-green-2 border-2 border-green-2 rounded mr-2 bg-prime-green-dark focus:shadow-none focus:ring-offset-0 focus:outline-none"
          type="checkbox"
          v-model="form.rememberMe"
        />
        {{ $t("pages.login.rememberMe") }}
      </label>
      <inertia-link
        :href="route('password.request')"
        class="mt-4 block text-sm text-green-2 w-max font-bold"
        >{{ $t("pages.login.forgotPassword") }}</inertia-link
      >
    </form>
  </div>
</template>
<script>
import { useForm } from "@inertiajs/inertia-vue3";
import VButton from "@/components/VButton.vue";
import AuthLayout from "./AuthLayout";
import route from "@/vendor/ziggy";

export default {
  components: { VButton },
  layout: AuthLayout,
  setup() {
    const form = useForm({
      email: null,
      password: null,
      rememberMe: true,
    });

    let url = route("login");

    const params = new URLSearchParams(window.location.search);
    const returnUrl = params.get("ReturnUrl");
    if (returnUrl && returnUrl.startsWith("/")) {
      url = `${url}?ReturnUrl=${returnUrl}`;
    }

    return { form, url };
  },
};
</script>
