<template>
  <input
    v-bind="$attrs"
    ref="input"
    :value="modelValue"
    :class="{ 'border-error border-2': errorMessage }"
    class="bg-gray-100 p-2 w-full text-gray-500"
    @input="$emit('update:modelValue', $event.target.value)"
  />
  <div v-if="errorMessage" class="text-error text-sm mt-2">
    {{ errorMessage }}
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    errorMessage: {
      default: null,
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  emits: ["update:modelValue"],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
