<template>
     <div class="flex flex-col items-center w-1/2 p-4 sm:w-28 has-tooltip">
        <a
          v-if="hasImage(file)"
          :href="
            route('api.files.content', {
              id: file.id,
              filename: file.originalName,
            })
          "
          target="_blank"
        >
          <img
              :src="
                route('api.files.content', {
                  id: file.id,
                  filename: file.originalName,
                  thumbnail: !!file.thumbnailUrl
                })
              "
              class="fill-current text-green-2 h-16 font-black inline-block cursor-pointer w-16 h-16"
          />
        </a>
        <a
          v-else
          :href="
            route('api.files.content', {
              id: file.id,
              filename: file.originalName,
            })
          "
          target="_blank"
        >
          <inline-svg 
              :src="getSvg(file.extension.toLowerCase())"
              class="fill-current text-green-2 h-16 font-black inline-block cursor-pointer w-16 h-16"
          />
        </a>
        <span v-if="showTooltip" class="tooltip rounded shadow-xl p-1 bg-black text-white -mt-8">{{ file.originalName }}</span>
        <a v-if="!hideFileName"
          :href="
            route('api.files.content', {
              id: file.id,
              filename: file.originalName,
            })
          "
          target="_blank"
        >
          <p 
            class="w-26 break-all text-text-btn text-center underline line-clamp-2 cursor-pointer"
          >{{ file.originalName }}</p>
        </a>
    </div>
</template>
<script>

import svgPdf from "@/assets/file-pdf-regular.svg";
import svgTxt from "@/assets/file-alt-regular.svg";
import svgFile from "@/assets/file-regular.svg";
import svgVideo from "@/assets/file-video-regular.svg";
import svgExcel from "@/assets/file-excel-regular.svg";
import svgWord from "@/assets/file-word-regular.svg";

export default {
  props: {
    file: Object,
    hideFileName: Boolean,
    showTooltip: Boolean
  },
  data() {
    return {
      svgPdf,
      svgTxt,
      svgFile,
      svgVideo,
      svgExcel,
      svgWord,
    };
  },
  methods: {
    hasImage(file){
      var extension = file.extension.toLowerCase();
      if (['.mp4'].includes(extension)){
        return file.thumbnailUrl != null
      }
      else if (['.png','.jpg','.jpeg'].includes(extension)) {
        return true
      }
      return false
    },
    getSvg(extension){
      switch(extension){
        case ".pdf":
          return this.svgPdf;
        case ".txt":
          return this.svgTxt;
        case ".mp4":
          return this.svgVideo;
        case ".docx":
          return this.svgWord;
        case ".xlsx":
          return this.svgExcel;
        default:
          return this.svgFile;
      }
    },
  }
};
</script>