<template>
  <app-layout
    :title="$t('components.slideMenu.export')"
    :returnHref="
      pageIndex == 0
        ? { path: 'home', properties: {} }
        : menu == 'support'
        ? {
            path: 'executive.export.support',
            properties: {
              menu,
              format,
              dateIndex,
              from,
              to,
              backIndex: pageIndex,
            },
            replace: true,
          }
        : menu == 'location'
        ? {
            path: 'executive.export.location',
            properties: {
              menu,
              format,
              country,
              regionId,
              backIndex: pageIndex,
            },
            replace: true,
          }
        : menu == 'solution'
        ? {
            path: 'executive.export.solution',
            properties: {
              menu,
              format,
              country,
              regionId,
              backIndex: pageIndex,
            },
            replace: true,
          }
        : {
            path: 'executive.export.support',
            properties: {
              menu,
              format,
              dateIndex,
              from,
              to,
              backIndex: pageIndex,
            },
            replace: true,
          }
    "
  >
    <div class="overflow-hidden">
      <div
        class="flex sm:justify-between w-screen-5 sm:w-screen sm:px-5 transform transition-all duration-500 ease-out"
        :class="{
          'translate-x-screen-1': pageIndex === 1,
          'translate-x-screen-2': pageIndex === 2,
          'translate-x-screen-3': pageIndex === 3,
          'translate-x-screen-4': pageIndex === 4,
        }"
      >
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <!-- placeholder button -->
            <v-button type="submit" @click="moduleNotActive" class="mb-4">
              <p class="mx-auto truncate ...">{{ $t("components.sla.sla") }}</p>
            </v-button>
            <inertia-link
              preserve-state
              :replace="true"
              v-for="(page, index) in pages"
              :key="index"
              :href="page.href"
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    menu == page.value,
                }"
              >
                <p class="mx-auto truncate ...">{{ page.label }}</p>
              </v-button>
            </inertia-link>
            <!-- placeholder button -->
            <v-button type="submit" @click="moduleNotActive" class="mb-4">
              <p class="mx-auto truncate ...">{{ $t("common.fields.co2") }}</p>
            </v-button>
          </transition-group>
        </div>
        <transition name="fade" mode="out-in">
          <div
            v-if="menu == 'solution'"
            class="flex sm:justify-between w-screen-4 sm:w-4/5"
          >
            <solution-report
              :menu="menu"
              :format="format"
              :toMail="toMail"
              :regions="regions"
              :regionId="regionId"
              :countries="countries"
              :country="country"
            />
          </div>
          <div
            v-else-if="menu == 'location'"
            class="flex sm:justify-between w-screen-4 sm:w-4/5"
          >
            <location-report
              :menu="menu"
              :format="format"
              :toMail="toMail"
              :regions="regions"
              :regionId="regionId"
              :countries="countries"
              :country="country"
            />
          </div>
          <div
            v-else-if="menu == 'support'"
            class="flex sm:justify-between w-screen-4 sm:w-4/5"
          >
            <support-report
              :menu="menu"
              :format="format"
              :toMail="toMail"
              :from="from"
              :to="to"
              :dateIndex="dateIndex"
            />
          </div>
        </transition>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from "@/Pages/AppLayout";
import LocationReport from "@/Pages/Export/LocationReport";
import SupportReport from "@/Pages/Export/SupportReport";
import SolutionReport from "@/Pages/Export/SolutionReport";
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

export default {
  components: { AppLayout, LocationReport, SupportReport, SolutionReport },
  props: [
    // All
    "menu",
    "format",
    "toMail",

    // Location & Solution
    "regions",
    "regionId",
    "countries",
    "country",

    // Support
    "dateIndex",
    "from",
    "to",
  ],
  data() {
    return {
      windowWidth: window.innerWidth,
      pages: [
        // {
        //   label: this.$t("components.sla.sla"),
        //   href: this.route("executive.export.location", { }),
        //   value: "sla",
        // },
        {
          label: this.$t("pages.executive.solutions"),
          href: this.route("executive.export.solution", { menu: "solution" }),
          value: "solution",
        },
        {
          label: this.$t("pages.executive.locations"),
          href: this.route("executive.export.location", { menu: "location" }),
          value: "location",
        },
        {
          label: this.$t("pages.mylocation.support"),
          href: this.route("executive.export.support", { menu: "support" }),
          value: "support",
        },
        // {
        //   label: this.$t("common.fields.co2"),
        //   href: this.route("executive.export.location", { }),
        //   value: "co2",
        // },
      ],
    };
  },
  computed: {
    pageIndex() {
      if (this.windowWidth >= 640) {
        return 0;
      } else if (this.toMail) {
        return 4;
      } else if (
        this.regionId ||
        this.country ||
        this.format == "all" ||
        this.dateIndex > 0
      ) {
        return 3;
      } else if (this.format) {
        return 2;
      } else if (this.menu) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    moduleNotActive() {
      alert(this.$t("common.placeholders.module.notActive"));
    },
  },
};
</script>
<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
}
</style>
