<template>
  <div class="absolute w-screen h-screen bg-prime-green-dark p-4">
    <div class="flex flex-col items-end">
      <inertia-link :href="route('home')">
        <inline-svg
          class="fill-current text-white h-8"
          :src="require('@/assets/burger-menu-open.svg')"
        />
      </inertia-link>
    </div>
    <div class="flex flex-col text-center items-center mx-10">
      <inline-svg
        class="fill-current text-green-2 h-24 w-24"
        :src="require('@/assets/file-document-outline.svg')"
      />
      <span class="inline-block text-xl text-white font-bold uppercase">
        {{ $t("pages.featureRequest.received") }}
      </span>
      <span class="inline-block text-lg text-white font-bold mt-12">
        {{ $t("pages.featureRequest.thankyou") }}
      </span>
      <i18n-t
        keypath="pages.newsupportcase.yourteam"
        tag="span"
        class="inline-block text-lg text-white mt-12 whitespace-pre-line"
      >
        <template #serviceDeskLink>
          <inertia-link :href="route('home')" class="text-green-2" replace>
            {{ $t("pages.newsupportcase.servicedesk") }}
          </inertia-link>
        </template>
      </i18n-t>
    </div>
  </div>
</template>
