<template>
  <app-layout
    :title="title"
    :return-href="
      index == 0
        ? { path: 'home', properties: {} }
        : {
            path: 'executive.solutions',
            properties: {
              solutionTemplateId,
              regionId: selectedRegion,
              country: selectedCountry,
              city: selectedCity,
              backIndex: index,
            },
            replace: true,
          }
    "
  >
    <div class="overflow-hidden">
      <div
        class="flex sm:justify-between w-screen-5 sm:w-screen sm:px-5 transform transition-all duration-500 ease-out"
        :class="{
          'translate-x-screen-1': index === 1,
          'translate-x-screen-2': index === 2,
          'translate-x-screen-3': index === 3,
          'translate-x-screen-4': index === 4,
        }"
      >
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <div
              class="border-2 border-prime-green mb-4 h-14 flex items-center"
            >
              <input
                v-model="searchString"
                class="border-0 bg-prime-background text-prime-green placeholder-prime-grey-dark italic font-bold focus:ring-0 flex-grow min-w-0"
                :placeholder="$t('pages.executive.solutionName')"
                type="text"
              />
              <inline-svg
                :src="require('@/assets/search-solid.svg')"
                class="fill-current text-prime-green h-8 font-black inline-block px-2 flex-shrink-0"
              />
            </div>
            <inertia-link
              v-for="solutionTemplate in filteredSolutionTemplates"
              :key="solutionTemplate.id"
              preserve-state
              :replace="true"
              :href="
                route('executive.solutions', {
                  solutionTemplateId: solutionTemplate.id,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    solutionTemplate.id == solutionTemplateId,
                }"
              >
                <p class="mx-auto line-clamp-2">
                  {{ solutionTemplate.name }}
                </p>
                <span class="mx-2 font-bold text-b2">{{
                  solutionTemplate.solutionCount
                }}</span>
              </v-button>
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <inertia-link
              v-for="region in regions"
              :key="region.id"
              preserve-state
              :replace="true"
              :href="
                route('executive.solutions', {
                  solutionTemplateId,
                  regionId: region.id,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    selectedRegion == region.id,
                }"
              >
                <p class="mx-auto line-clamp-2">
                  {{
                    region.name === "region.unknown"
                      ? $t(`common.placeholders.${region.name}`)
                      : region.name
                  }}
                </p>
                <span class="mx-2 font-bold text-b2">{{ region.solutionCount }}</span>
              </v-button>
            </inertia-link>
            <div
              v-if="selectedSolutionTemplate"
              class="flex items-center justify-center h-14 mb-4"
            >
              <p class="text-center text-prime-grey-dark line-clamp-2 italic">
                {{ $t("pages.executive.solutionTypeInfo") }}
              </p>
            </div>
            <v-button
              v-if="selectedSolutionTemplate"
              type="submit"
              class="mb-4"
              @click="
                historyVisit(
                  PageKeys.SolutionTemplate,
                  route('executive.solution-templates.info', { id: solutionTemplateId })
                )
              "
            >
              <p class="mx-auto line-clamp-2">
                {{ selectedSolutionTemplate.name }}
              </p>
            </v-button>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <inertia-link
              v-for="country in countries"
              :key="country.name"
              preserve-state
              :replace="true"
              :href="
                route('executive.solutions', {
                  solutionTemplateId,
                  regionId: selectedRegion,
                  country: country.name,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    selectedCountry == country.name,
                }"
              >
                <p class="mx-auto line-clamp-2">
                  {{
                    country.name === "country.unknown"
                      ? $t(`common.placeholders.${country.name}`)
                      : country.name
                  }}
                </p>
                <span class="mx-2 font-bold text-b2">{{ country.solutionCount }}</span>
              </v-button>
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <inertia-link
              v-for="city in cities"
              :key="city.name"
              preserve-state
              :replace="true"
              :href="
                route('executive.solutions', {
                  solutionTemplateId,
                  regionId: selectedRegion,
                  country: selectedCountry,
                  city: city.name,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    selectedCity == city.name,
                }"
              >
                <p class="mx-auto line-clamp-2">
                  {{
                    city.name === "city.unknown"
                      ? $t(`common.placeholders.${city.name}`)
                      : city.name
                  }}
                </p>
                <span class="mx-2 font-bold text-b2">{{
                  city.solutionCount
                }}</span></v-button
              >
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <v-button
              v-for="location in locations"
              :key="location.id"
              type="submit"
              class="mb-4"
              @click="
                historyVisit(
                  PageKeys.Location,
                  route('executive.locations.show', { id: location.id })
                )
              "
            >
              <p class="mx-auto line-clamp-2">
                {{
                  !location.addressLine1
                    ? $t("common.placeholders.street.unknown")
                    : location.addressLine1
                }}
              </p>
              <span class="mx-2 font-bold text-b2">{{ location.solutionCount }}</span>
            </v-button>
          </transition-group>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from "@/Pages/AppLayout";
import { PageKeys, SetHistory } from "@/util/navigationHistory.js";

export default {
  components: { AppLayout },
  props: [
    "locations",
    "solutionTemplates",
    "solutionTemplateId",
    "regions",
    "selectedRegion",
    "countries",
    "selectedCountry",
    "cities",
    "selectedCity",
  ],
  data() {
    return {
      PageKeys,
      organization: this.$page.props.auth.organization,
      windowWidth: window.innerWidth,
      searchString: "",
    };
  },
  computed: {
    index() {
      if (this.windowWidth >= 640) {
        return 0;
      } else if (this.selectedCity) {
        return 4;
      } else if (this.selectedCountry) {
        return 3;
      } else if (this.selectedRegion) {
        return 2;
      } else if (this.solutionTemplateId) {
        return 1;
      } else {
        return 0;
      }
    },
    title() {
      if (this.index == 1) {
        var type = this.solutionTemplates.find(
          (solutionTemplate) => solutionTemplate.id == this.solutionTemplateId
        );
        if (type) {
          return type.solutionTemplate.name;
        }
      } else if (this.index == 2) {
        const region = this.regions.find(
          (r) => r.id === this.selectedRegion
        );
        return region.name === "region.unknown"
          ? this.$t(`common.placeholders.${region.name}`)
          : region.name;
      } else if (this.index == 3) {
        return this.selectedCountry === "country.unknown"
          ? this.$t(`common.placeholders.${this.selectedCountry}`)
          : this.selectedCountry;
      } else if (this.index == 4) {
        return this.selectedCity === "city.unknown"
          ? this.$t(`common.placeholders.${this.selectedCity}`)
          : this.selectedCity;
      }
      return "solutions";
    },
    filteredSolutionTemplates() {
      return this.solutionTemplates.filter((x) =>
        x.name
          .toLowerCase()
          .includes(this.searchString.toLowerCase())
      );
    },
    selectedSolutionTemplate() {
      return this.solutionTemplates.find(
        (x) => x.id == this.solutionTemplateId
      );
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    historyVisit(pageKey, route) {
      SetHistory(pageKey, "executive.solutions", {
        solutionTemplateId: this.solutionTemplateId,
        regionId: this.selectedRegion,
        country: this.selectedCountry,
        city: this.selectedCity,
      });
      this.$inertia.visit(route);
    },
  },
};
</script>
<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
</style>
