<template>
  <app-layout
    :title="title"
    :returnHref="
      index != 0
        ? {
            path: 'executive.locations.show',
            properties: {
              id: location.id,
              backIndex: index,
              city: location.city,
            },
            replace: true,
          }
        : returnHref
        ? returnHref
        : {
            path: 'executive.locations',
            properties: {
              locationTypeId: location.locationType.id,
              regionId: regionId,
              country: location.country,
              city: location.city,
            },
          }
    "
  >
    <div class="overflow-hidden">
      <div
        class="flex sm:justify-between w-screen-3 sm:w-full sm:px-5 transform transition-all duration-500 ease-out"
        :class="{
          'translate-x-screen-1': index === 1,
          'translate-x-screen-2': index === 2,
        }"
      >
        <!-- desktop -->
        <div
          v-if="desktopView"
          class="flex flex-col w-full sm:w-1/5 px-5 sm:pr-2 sm:pl-5 font-bold"
        >
          <v-button class="mb-10 bg-prime-green-dark">
            <p class="line-clamp-2">{{ location.locationType.name }}</p>
          </v-button>
          <div class="text-prime-green mb-6 mx-2 text-b2">
            <p class="uppercase">{{ location.locationType.name }}</p>
            <p>
              {{
                !location.addressLine1
                  ? $t("common.placeholders.street.unknown")
                  : location.addressLine1
              }}
            </p>
            <p>
              {{
                !location.city
                  ? $t("common.placeholders.city.unknown")
                  : location.postalCode
                  ? location.postalCode + " " + location.city
                  : location.city
              }}
            </p>
            <p class="mb-6">
              {{
                !location.country
                  ? $t("common.placeholders.street.unknown")
                  : location.country
              }}
            </p>
            <p v-if="location.installedAt != null" class="uppercase">
              {{ $t("common.fields.install.date") }}
            </p>
            <p v-if="location.installedAt != null">
              {{ formatDateTime(location.installedAt) }}
            </p>
          </div>
          <inertia-link
            class="px-2 mb-4"
            preserve-state
            :href="route('executive.locations.documents', location.id)"
          >
            <div
              class="flex items-center text-prime-green text-b2 uppercase underline"
            >
              {{ $t("pages.executive.locationDocuments") }}
            </div>
          </inertia-link>
        </div>
        <!-- end desktop -->
        <div class="flex flex-col w-screen sm:w-1/5 px-5 sm:px-2">
          <inertia-link
            preserve-state
            replace="true"
            v-for="solution in solutions"
            :key="solution.id"
            :href="
              route('executive.locations.show', {
                id: location.id,
                solutionId: solution.id,
              })
            "
          >
            <v-button
              type="submit"
              class="mb-8"
              @click="selectedSolution = solution"
            >
              <div class="flex justify-between items-center w-full">
                <span class="mx-2 font-bold text-b2">{{
                  solution.quantity
                }}</span>
                <p class="line-clamp-2">{{ solution.templateName }}</p>
                <inline-svg
                  :src="require('@/assets/circle.svg')"
                  class="w-4 inline-block fill-current mx-2 flex-shrink-0"
                  :class="
                    !!solution.activeSupportCaseId
                      ? 'text-prime-red-dark'
                      : 'text-indicator-green'
                  "
                />
              </div>
            </v-button>
          </inertia-link>
          <!-- mobile -->
          <div v-if="!desktopView" class="flex flex-col w-full mt-2 font-bold">
            <div class="text-prime-green mb-6 text-b2">
              <p class="uppercase">{{ location.locationType.name }}</p>
              <p>
                {{
                  !location.addressLine1
                    ? $t("common.placeholders.street.unknown")
                    : location.addressLine1
                }}
              </p>
              <p>
                {{
                  !location.city
                    ? $t("common.placeholders.city.unknown")
                    : location.postalCode
                    ? location.postalCode + " " + location.city
                    : location.city
                }}
              </p>
              <p class="mb-6">
                {{
                  !location.country
                    ? $t("common.placeholders.street.unknown")
                    : location.country
                }}
              </p>
              <p class="uppercase">{{ $t("common.fields.install.date") }}</p>
              <p>{{ formatDateTime(location.installedAt) }}</p>
            </div>
            <inertia-link
              class="mb-4"
              preserve-state
              :href="route('executive.locations.documents', location.id)"
            >
              <div
                class="flex items-center text-prime-green text-b2 uppercase underline"
              >
                {{ $t("pages.executive.locationDocuments") }}
              </div>
            </inertia-link>
          </div>
          <!-- end mobile -->
        </div>
        <div class="flex flex-col w-screen sm:w-3/5 px-5 sm:pl-2 sm:pr-5">
          <div
            v-for="solution in solutionsShown"
            :key="solution.id"
            class="flex flex-col sm:flex-row"
          >
            <div class="flex flex-col sm:flex-row w-full">
              <inertia-link
                v-if="solution.id && solution.hasActiveSla"
                preserve-state
                :href="route('executive.solutions.show-sla', solution.id)"
                class="mb-8 w-full sm:pr-2"
              >
                <v-button type="submit">
                  <div class="flex justify-between items-center w-full">
                    <svg
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 inline-block mx-2"
                    />
                    {{ $t("components.sla.sla") }}
                    <inline-svg
                      :src="require('@/assets/circle.svg')"
                      class="w-4 inline-block fill-current mx-2"
                      :class="
                        solution.hasActiveSla
                          ? 'text-indicator-green'
                          : 'text-prime-red-dark'
                      "
                    />
                  </div>
                </v-button>
              </inertia-link>
              <span
                v-if="!solution || !solution.hasActiveSla"
                class="mb-8 w-full sm:pr-2"
              >
                <v-button type="submit">
                  <div class="flex justify-between items-center w-full">
                    <svg
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 inline-block mx-2"
                    />
                    {{ $t("components.sla.sla") }}
                    <inline-svg
                      :src="require('@/assets/circle.svg')"
                      class="w-4 inline-block fill-current mx-2"
                      :class="
                        solution.hasActiveSla
                          ? 'text-indicator-green'
                          : 'text-prime-red-dark'
                      "
                    />
                  </div>
                </v-button>
              </span>
            </div>
            <inertia-link
              preserve-state
              :href="route('executive.solutions.info', solution.id)"
              class="mb-8 w-full sm:px-2"
            >
              <v-button type="submit">
                {{ $t("common.fields.info") }}
              </v-button>
            </inertia-link>
            <v-button
              v-if="
                !!solution.activeSupportCaseId
              "
              type="submit"
              @click="
                historyVisit(
                  PageKeys.Support,
                  route(
                    'executive.support-cases.show',
                    solution.activeSupportCaseId
                  )
                )
              "
              class="mb-8 w-full sm:pl-2"
            >
              <div class="text-center w-full">
                {{ $t("pages.mylocation.support") }}
              </div>
            </v-button>
            <v-button
              v-else
              type="submit"
              @click="
                historyVisit(
                  PageKeys.Support,
                  route('support-cases.create', {
                    solutionId: solution.id,
                    locationId: location.id,
                  })
                )
              "
              class="mb-8 w-full sm:pl-2"
            >
              <div class="text-center w-full">
                {{ $t("pages.mylocation.support") }}
              </div>
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import { PageKeys, GetHistory, SetHistory } from "@/util/navigationHistory.js";
import AppLayout from "../Pages/AppLayout";
import { isSlaActive } from "@/util/slaHelpers";

export default {
  components: { AppLayout },
  props: ["location", "solutions", "solutionId"],
  data() {
    return {
      PageKeys,
      returnHref: null,
      organization: this.$page.props.auth.organization,
      selectedSolution: null,
      desktopView: window.innerWidth >= 640,
    };
  },
  computed: {
    index() {
      if (this.desktopView) {
        return 0;
      } else if (this.solutionId) {
        return 1;
      } else {
        return 0;
      }
    },
    title() {
      if (this.index == 1) {
        var solution = this.solutions.find((x) => x.id == this.solutionId);
        return solution.templateName;
      }
      return this.$t("common.fields.location");
    },
    solutionsShown() {
      if (this.desktopView) {
        return this.solutions;
      } else if (this.solutionId) {
        var solution = this.solutions.find((x) => x.id == this.solutionId);
        return [solution];
      }
      return [];
    },
    regionId() {
      return this.location?.regionId ?? "00000000-0000-0000-0000-000000000000";
    },
  },
  mounted() {
    this.returnHref = GetHistory(PageKeys.Location);
    window.addEventListener("resize", () => {
      this.desktopView = window.innerWidth >= 640;
    });
  },
  methods: {
    isSlaActive,
    isAnySlaActive(slaContents) {
      return slaContents.some((slaContent) => isSlaActive(slaContent));
    },
    isAnyLocationSupportCaseActive() {
      var active = this.solutions.some((solution) => {
        this.isAnySupportCaseActive(solution.supportCases);
      });
      if (active) {
        return true;
      }
      return this.isAnySupportCaseActive(this.location.supportCases);
    },
    isAnySupportCaseActive(supportCases) {
      return supportCases.some((supportCase) => supportCase.status != "Closed");
    },
    formatDateTime(dateTimeString) {
      if (!dateTimeString) {
        return this.$t("common.fields.install.not");
      }

      const date = new Date(dateTimeString);

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return `${months[date.getMonth()]} ${date
        .getDate()
        .toString()
        .padStart(2, "0")}, ${date.getFullYear()}`;
    },
    historyVisit(pageKey, route) {
      SetHistory(pageKey, "executive.locations.show", {
        id: this.location.id,
        backIndex: this.index,
        city: this.location.city,
      });
      this.$inertia.visit(route);
    },
  },
};
</script>
