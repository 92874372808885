<template>
  <support-layout :menuIndex="3">
    <form
      class="mb-4 flex-0 flex items-center bg-white border-2 border-solid border-prime-grey-dark text-prime-black"
      @submit.prevent="updateFilters"
    >
      <input
        class="border-0 flex-grow"
        type="text"
        :placeholder="$t('pages.supportcase.searchplaceholder')"
        v-model="search"
      />
      <inline-svg
        :src="require('@/assets/search.svg')"
        class="fill-current cursor-pointer text-gray-600 h-8 mr-1"
        @click="updateFilters"
      />
    </form>
    <div
      class="flex-1 flex flex-col bg-white border-2 border-solid border-prime-grey-dark text-prime-black"
    >
      <div class="grid grid-cols-3">
        <div
          class="mt-2 mr-2 px-4 col-span-3 md:px-0 2xl:col-start-2 2xl:col-span-2 md:col-start-2 md:col-span-2"
        >
          <div class="flex flex-wrap text-center gap-2">
            <p class="flex-auto hidden sm:block sm:visible">
              {{ $t("pages.supportcase.filter.title") }}
            </p>
            <a
              v-for="status in statuses"
              :key="status.value"
              class="flex-auto text-gray-500 font-bold border-2 cursor-pointer mx-1 px-1"
              :class="filters.status === status.value ? 'border-black' : ''"
              @click="setStatusFilter(status.value)"
            >
              {{ status.text }}
            </a>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <inertia-link
          v-for="supportCase in supportCases.data"
          :key="supportCase.id"
          :href="route('executive.support-cases.show', { id: supportCase.id })"
          class="grid grid-cols-20 gap-2 py-4 border-b-2 border-gray-900"
        >
          <div class="col-span-5 md:col-span-3">
            <p class="text-b2 font-bold pl-4">{{ supportCase.referenceKey }}</p>
          </div>
          <div class="col-span-15 md:col-span-7">
            <p class="text-b2 font-bold text-right pr-4 md:text-left md:pr-0">
              {{ supportCase.location ? supportCase.location.name : "" }}
            </p>
            <p class="text-b2 hidden md:block">
              {{ formatSolution(supportCase.solution) }}
            </p>
          </div>
          <div class="col-span-10 md:col-span-6">
            <p class="text-b2 font-bold pl-4 md:pl-0">
              {{
                formatDateTime(
                  supportCase.lastMessageReceivedAt ||
                    supportCase.manualStartedAt ||
                    supportCase.createdAt
                )
              }}
            </p>
            <p class="text-b2 hidden md:block">
              {{ supportCase.name }}
            </p>
          </div>
          <div class="col-span-10 md:col-span-4">
            <p class="text-b2 font-bold text-right pr-4">
              {{
                $t(
                  `common.supportCaseStatuses.${supportCase.status}`
                ).toUpperCase()
              }}
            </p>
          </div>
        </inertia-link>
      </div>
      <div class="grid grid-cols-3">
        <div
          class="px-4 col-span-3 md:px-0 2xl:col-start-3 2xl:col-span-1 md:col-start-2 md:col-span-2 pagination select-none"
        >
          <ol class="text-right">
            <li
              v-for="pageNumber in supportCases.pageCount"
              :key="`support-case-page-${pageNumber}`"
              class="mx-2 inline-block"
              :class="{
                active: pagination.page === pageNumber,
                'active-sibling': pagination.page - 1 === pageNumber,
              }"
            >
              <a
                class="text-gray-500 font-bold underline cursor-pointer"
                @click="setPage(pageNumber)"
              >
                {{ pageNumber }}
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </support-layout>
</template>
<script>
import SupportLayout from "./SupportLayout.vue";
import { pascalToSpaces } from "@/util/text";

export default {
  components: {
    SupportLayout,
  },
  props: {
    supportCases: Object,
  },
  data() {
    const params = new URLSearchParams(location.search);
    return {
      search: decodeURIComponent(
        params.get("search")?.replace("/hashtag/", "#") ?? ""
      ),
      filters: {
        status: params.get("status"),
        search: params.get("search"),
      },
      pagination: {
        page: this.supportCases.currentPage,
        itemsPerPage: 10,
      },
      statuses: [
        { text: this.$t("pages.supportcase.filter.open"), value: "Open" },
        { text: this.$t("pages.supportcase.filter.inProgress"), value: "InProgress" },
        { text: this.$t("pages.supportcase.filter.closed"), value: "Closed" },
        { text: this.$t("pages.supportcase.filter.thirdParty"), value: "ThirdParty" },
        { text: this.$t("pages.supportcase.filter.showAll"), value: null },
      ]
    };
  },
  methods: {
    pascalToSpaces,
    formatSolution(solution) {
      if (!solution) {
        return "";
      }

      return solution.name;
    },
    formatDateTime(dateTimeString) {
      if (!dateTimeString) {
        return this.$t("common.time.unavailable");
      }

      const date = new Date(dateTimeString);

      const months = [
        this.$t("common.months.abbreviated.january"),
        this.$t("common.months.abbreviated.february"),
        this.$t("common.months.abbreviated.march"),
        this.$t("common.months.abbreviated.april"),
        this.$t("common.months.abbreviated.may"),
        this.$t("common.months.abbreviated.june"),
        this.$t("common.months.abbreviated.july"),
        this.$t("common.months.abbreviated.august"),
        this.$t("common.months.abbreviated.september"),
        this.$t("common.months.abbreviated.october"),
        this.$t("common.months.abbreviated.november"),
        this.$t("common.months.abbreviated.december"),
      ];

      return `${months[date.getMonth()]} ${date
        .getDate()
        .toString()
        .padStart(2, "0")}, ${date.getFullYear()}`;
    },
    setStatusFilter(status) {
      this.filters.status = status;
    },
    changePage(options) {
      let query = {
        status: this.filters.status ?? undefined,
        search: this.filters.search ?? undefined,
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("executive.support-cases", query),
        {},
        {
          preserveState: true,
          preserveScroll: true,
          replace: true,
        }
      );
    },
    setPage(pageNumber) {
      this.pagination.page = pageNumber;
      this.changePage(this.pagination);
    },
    updateFilters() {
      this.filters.search = this.search.replace("#", "/hashtag/");
    },
  },
  watch: {
    filters: {
      handler: function () {
        this.pagination = { page: 1, itemsPerPage: 10 };
        this.changePage(this.pagination);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin ellipsis($before: true) {
  content: "\2026";
  font-size: 24px;
  display: inline-block;
  @if ($before) {
    margin-right: 0.5rem;
  } @else {
    margin-left: 0.5rem;
  }
}

// Too much to override, just do the difference
@media (max-width: 767px) {
  li {
    &:first-child,
    &.active,
    &.active-sibling:nth-last-child(2), // Show second to last child if the last one is active
    &:last-child {
      display: inline-block !important;
    }

    $how-many-on-ends: 3; // 1,2,3,...,10 || 1,...,8,9,10
    // There are >= 5 pages
    &:first-child:nth-last-child(n + 6) {
      & ~ li {
        // Start out with all siblings hidden
        display: none;

        // Show the last children in the list by default
        &:nth-last-child(-n + #{$how-many-on-ends}) {
          display: inline-block;
        }

        // The child at the beginning of the last group shows ellipsis for the group
        &:nth-last-child(#{$how-many-on-ends}) {
          &:before {
            @include ellipsis(true);
          }
        }

        // The very beginning elements do not need to show ellipsis
        // The very end elements do not need to show ellipsis
      }

      &.active,
      & ~ li.active {
        // Show ellipsis before and after the active element
        &:before {
          @include ellipsis(true);
        }
        &:after {
          @include ellipsis(false);
        }

        // If the active element is in the first or last group, don't show ellipsis (siblings will take care of it)
        &:nth-child(-n + #{$how-many-on-ends - 1}),
        &:nth-last-child(-n + #{$how-many-on-ends - 1}) {
          &:before,
          &:after {
            display: none;
          }
        }

        // Hide the last group if "active" comes before them
        & ~ li:nth-last-child(-n + #{$how-many-on-ends}) {
          display: none;
        }

        // Show the first group together if "active" comes before them
        & ~ li:nth-child(-n + #{$how-many-on-ends}) {
          display: inline-block;
        }

        // If "active" is before the last member in the group, don't show ellipsis
        & ~ li:nth-child(-n + #{$how-many-on-ends - 1}) {
          &:after {
            display: none;
          }
        }

        // The child at the end of the first group shows ellipsis for the group
        & ~ li:nth-child(#{$how-many-on-ends}) {
          &:after {
            @include ellipsis(false);
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  li {
    &:first-child,
    &.active-sibling,
    &.active,
    &.active + li,
    &:last-child {
      display: inline-block !important;
    }

    // There are >= 7 pages
    &:first-child:nth-last-child(n + 8) {
      $how-many-on-ends: 5; // 1,2,3,4,5,...,10 || 1,...6,7,8,9,10

      & ~ li {
        // Start out with all siblings hidden
        display: none;

        // Show ellipsis before the previous one
        &.active-sibling:before {
          @include ellipsis(true);
        }
        // Show ellipsis after the next one
        &.active + li:after {
          @include ellipsis(false);
        }

        // Show the last children in the list by default
        &:nth-last-child(-n + #{$how-many-on-ends}) {
          display: inline-block;
        }

        // The child at the beginning of the last group shows ellipsis for the group
        &:nth-last-child(#{$how-many-on-ends}) {
          &:before {
            @include ellipsis(true);
          }
        }

        // The very beginning elements do not need to show ellipsis
        &:nth-child(-n+#{$how-many-on-ends - 3}),
        // The very end elements do not need to show ellipsis
        &:nth-last-child(-n+#{$how-many-on-ends - 3}),
        // Even if it's a sibling to "active"
        &.active-sibling:nth-last-child(-n+#{$how-many-on-ends - 1}) {
          &:before,
          &:after {
            display: none !important;
          }
        }
      }

      &.active,
      & ~ li.active {
        // Hide the last group if "active" comes before them
        & ~ li:nth-last-child(-n + #{$how-many-on-ends}) {
          display: none;

          // If there is overlap, the element will show, but hide it's ellipsis
          &:before {
            display: none;
          }
        }

        // Show the first group together if "active" comes before them
        & ~ li:nth-child(-n + #{$how-many-on-ends}) {
          display: inline-block;
        }

        // If "active" is before the last member in the group, don't show ellipsis
        & ~ li:nth-child(-n + #{$how-many-on-ends - 1}) {
          &:after {
            display: none;
          }
        }

        // The child at the end of the first group shows ellipsis for the group
        & ~ li:nth-child(#{$how-many-on-ends}) {
          &:after {
            @include ellipsis(false);
          }
        }

        // "active" should never show ellipsis
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
