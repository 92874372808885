<template>
  <div class="mt-2">
    <div class="space-y-2">
      <div
        v-for="content in contents"
        :key="content.id"
        class="border-b pb-4 border-gray-300"
      >
        <div class="text-lg text-brand2">
          {{ content.informationType.name }}
        </div>
        <div class="text-xs mb-4" v-html="content.description" />
        <div v-for="file in content.files" :key="file.id">
          <div class="flex justify-between text-sm">
            <div class="break-all">
              {{ file.name }}
            </div>
            <a
              class="text-brand7 font-bold ml-4"
              target="_blank"
              :href="route('api.files.content', {
                id: file.id,
                filename: file.originalName,
              })"
              >{{ $t("pages.mylocation.download") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contents: Array,
  },
};
</script>
