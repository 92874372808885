<template>
  <app-layout :title="'Account Settings'">
    <div class="overflow-hidden">
      <div
        class="lg:mx-12 lg:mt-4 mt-0 mx-5 transform transition-all duration-500 ease-out"
      >
        <div class="grid grid-cols-1 lg:grid-cols-10">
          <div class="col col-span-4">
            <h1
              class="text-b2 text-prime-green font-extrabold mb-4 text-center sm:text-left"
            >
              {{ $t("pages.accountSettings.aboutYou") }}
            </h1>
            <table class="text-green-2 font-bold text-b3">
              <tr>
                <td>{{ $t("pages.accountSettings.name") }}:</td>
                <td class="text-prime-grey-darkest">{{ user.name }}</td>
              </tr>
              <tr>
                <td>{{ $t("pages.accountSettings.email") }}:</td>
                <td class="text-prime-grey-darkest break-all">
                  {{ user.email }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("pages.accountSettings.role") }}:</td>
                <td class="text-prime-grey-darkest">{{ user.role.name }}</td>
              </tr>
              <tr>
                <td class="pr-4">
                  {{ $t("pages.accountSettings.organisation") }}:
                </td>
                <td class="text-prime-grey-darkest">
                  {{ organization.name }}
                </td>
              </tr>
            </table>
            <div class="grid grid-cols-1 lg:grid-cols-2">
              <div class="col col-span-1">
                <h1
                  class="text-b2 text-prime-green font-extrabold my-4 text-center sm:text-left"
                >
                  {{ $t("pages.accountSettings.language") }}
                </h1>
                <multiselect
                  v-model="languageForm.locale"
                  mode="single"
                  :options="formattedLocales"
                  :close-on-select="true"
                  :searchable="true"
                  label="name"
                  track-by="value"
                  :can-clear="false"
                  :can-deselect="false"
                  :placeholder="$t('pages.accountSettings.language')"
                  class="text-prime-green uppercase mb-4 font-black"
                  :classes="multiselectClasses"
                  @select="changeLocale"
                />
              </div>
            </div>
          </div>
          <div class="col col-span-6 sm:max-w-md">
            <h1
              class="text-b2 text-prime-green font-extrabold mb-4 lg:mt-0 mt-10 text-center sm:text-left"
            >
              {{ $t("pages.accountSettings.changePassword") }}
            </h1>
            <form @submit.prevent="updatePassword">
              <div class="mb-4">
                <label class="text-green-2 font-bold text-b3">{{
                  $t("pages.accountSettings.currentPassword")
                }}</label>
                <v-input
                  ref="currentPassword"
                  v-model="form.currentPassword"
                  class="text-prime-black border-green-2 px-4 py-2 w-full border-2 focus:ring-0 focus:border-prime-green"
                  type="password"
                  required
                  :error-message="form.errors.currentPassword"
                />
              </div>

              <div class="mb-4">
                <label class="text-green-2 font-bold text-b3">{{
                  $t("pages.accountSettings.newPassword")
                }}</label>
                <v-input
                  ref="newPassword"
                  v-model="form.password"
                  class="text-prime-black border-green-2 px-4 py-2 w-full border-2 focus:ring-0 focus:border-prime-green"
                  type="password"
                  required
                  :error-message="form.errors.password"
                />
              </div>

              <div class="mb-4">
                <label class="text-green-2 font-bold text-b3">{{
                  $t("pages.accountSettings.confirmPassword")
                }}</label>
                <v-input
                  ref="passwordConfirmation"
                  v-model="form.passwordConfirmation"
                  class="text-prime-black border-green-2 px-4 py-2 w-full border-2 focus:ring-0 focus:border-prime-green"
                  type="password"
                  required
                  :error-message="form.errors.passwordConfirmation"
                />
              </div>

              <v-button
                type="submit"
                class="mt-8 py-2 h-10 uppercase text-white w-full self-end base-button sm:w-4/5"
                :disabled="!isFormFilledOut"
                >{{ $t("pages.accountSettings.updatePassword") }}</v-button
              >

              <div
                v-if="form.recentlySuccessful"
                v-t="'pages.accountSettings.passwordUpdated'"
                class="sm:text-center text-left text-green-500 mt-4 font-bold"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import VInput from "../../components/VInput.vue";
import AppLayout from "../AppLayout.vue";
import Multiselect from "@vueform/multiselect";
import "@/assets/scss/multiselect-tailwind.scss";
import multiselectClasses from "@/util/multiselectClasses.js";

export default {
  components: { AppLayout, VInput, Multiselect },
  props: {
    user: Object,
    locales: Array,
    auth: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
      }),
      languageForm: this.$inertia.form({
        locale: this.user.preferredLocale,
      }),
      multiselectClasses,
    };
  },
  computed: {
    isFormFilledOut() {
      return (
        this.form.currentPassword &&
        this.form.password &&
        this.form.passwordConfirmation
      );
    },
    formattedLocales() {
      return this.locales.map((x) => ({
        name: this.$t(`pages.accountSettings.languages.${x}`),
        value: x,
      }));
    },
    organization() {
      return this.auth.organization;
    }
  },
  methods: {
    updatePassword() {
      this.form.put(this.route("user-password.update"), {
        preserveScroll: true,
        onSuccess: () => this.form.reset(),
        onError: () => {
          if (this.form.errors.password) {
            this.form.reset("password", "passwordConfirmation");
          }
          if (this.form.errors.currentPassword) {
            this.form.reset("currentPassword");
          }
        },
      });
    },
    changeLocale() {
      this.languageForm.patch(this.route("users.language.update"), {
        preserveScroll: true,
        onSuccess: () => {
          this.$i18n.locale = this.languageForm.locale;
        },
      });
    },
  },
};
</script>

<style></style>
