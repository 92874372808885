<template>
  <app-layout
    :title="title"
    :return-href="
      index == 0
        ? { path: 'home', properties: {} }
        : {
            path: 'executive.locations',
            properties: {
              locationTypeId,
              regionId: selectedRegion,
              country: selectedCountry,
              city: selectedCity,
              backIndex: index,
            },
            replace: true,
          }
    "
  >
    <div class="overflow-hidden">
      <div
        class="flex sm:justify-between w-screen-5 sm:w-screen sm:px-5 transform transition-all duration-500 ease-out"
        :class="{
          'translate-x-screen-1': index === 1,
          'translate-x-screen-2': index === 2,
          'translate-x-screen-3': index === 3,
          'translate-x-screen-4': index === 4,
        }"
      >
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <multiselect
              v-model="locationId"
              mode="single"
              :value="locationId"
              :options="searchableLocations"
              :classes="multiselectClasses"
              :searchable="true"
              :allow-empty="true"
              :placeholder="$t('pages.executiveLocations.searchLocations')"
              value-prop="id"
              track-by="searchText"
              label="name"
            >
              <template #caret="{ toggle }">
                <span class="w-10 h-10 box-content mx-2 relative z-10 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none">
                  <inline-svg
                    :src="require('@/assets/search-solid.svg')"
                    class="fill-current text-prime-green h-8 font-black inline-block px-2 flex-shrink-0"
                    @click="toggle"
                  />
                </span>
              </template>
              <template #option="{ option }">
                  <span class="w-full">{{ option.name }}</span>
                  <span v-if="option.subtitle !== ''" class="w-full text-sm text-gray-500">
                    {{ option.subtitle }}
                  </span>
              </template>
            </multiselect>
            <inertia-link
              v-for="locationType in locationTypes"
              :key="locationType.id"
              preserve-state
              :replace="true"
              :href="
                route('executive.locations', {
                  locationTypeId: locationType.id,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    locationType.id == locationTypeId,
                }"
              >
                <p class="line-clamp-2">{{ locationType.name }}</p>
              </v-button>
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <inertia-link
              v-for="region in regions"
              :key="region.id"
              preserve-state
              :replace="true"
              :href="
                route('executive.locations', {
                  locationTypeId,
                  regionId: region.id,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    selectedRegion == region.id,
                }"
              >
                <p class="line-clamp-2">
                  {{
                    region.name === "region.unknown"
                      ? $t(`common.placeholders.${region.name}`)
                      : region.name
                  }}
                </p>
              </v-button>
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <inertia-link
              v-for="country in countries"
              :key="country.name"
              preserve-state
              :replace="true"
              :href="
                route('executive.locations', {
                  locationTypeId,
                  regionId: selectedRegion,
                  country: country.name,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    selectedCountry == country.name,
                }"
              >
                <p class="line-clamp-2">
                  {{
                    country.name === "country.unknown"
                      ? $t(`common.placeholders.${country.name}`)
                      : country.name
                  }}
                </p>
              </v-button>
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <inertia-link
              v-for="city in cities"
              :key="city.name"
              preserve-state
              :replace="true"
              :href="
                route('executive.locations', {
                  locationTypeId,
                  regionId: selectedRegion,
                  country: selectedCountry,
                  city: city.name,
                })
              "
              class="mb-4"
            >
              <v-button
                type="submit"
                :class="{
                  'bg-prime-green-dark hover:bg-prime-green-dark':
                    selectedCity == city.name,
                }"
              >
                <p class="line-clamp-2">
                  {{
                    city.name === "city.unknown"
                      ? $t(`common.placeholders.${city.name}`)
                      : city.name
                  }}
                </p>
              </v-button>
            </inertia-link>
          </transition-group>
        </div>
        <div class="flex flex-col w-screen sm:w-1/5 px-5">
          <transition-group name="fade">
            <v-button
              v-for="location in locations"
              :key="location.id"
              type="submit"
              @click="
                historyVisit(
                  route('executive.locations.show', { id: location.id })
                )
              "
              class="mb-4"
            >
              <p class="line-clamp-2">
                {{ getLocationName(location) }}
              </p>
            </v-button>
          </transition-group>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from "../Pages/AppLayout";
import { PageKeys, SetHistory } from "@/util/navigationHistory.js";
import Multiselect from "@vueform/multiselect";
import "@/assets/scss/multiselect-tailwind.scss";

export default {
  components: { AppLayout, Multiselect },
  props: {
    locations: Array,
    locationTypes: Array,
    locationTypeId: String,
    regions: Array,
    selectedRegion: String,
    countries: Array,
    selectedCountry: String,
    cities: Array,
    selectedCity: String,
    searchLocations: Array,
  },
  data() {
    return {
      organization: this.$page.props.auth.organization,
      windowWidth: window.innerWidth,
      locationNumber: null,
      locationId: null,
      multiselectClasses: {
        container: "relative location-search-container mb-4 mx-auto w-full flex items-center justify-end box-border cursor-pointer bg-white text-base leading-snug outline-none border-solid border-prime-green border-2 py-2 focus:shadow-none",
        caret: 'w-10 h-10 box-content mr-2 relative z-10 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
        caretOpen: 'pointer-events-auto',
        singleLabelText: 'text-black',
        option: 'multiselect-option text-black flex flex-col',
        optionSelected: 'bg-prime-green text-white',
        optionSelectedPointed: 'bg-prime-green text-white',
        clear: 'multiselect-clear !pr-1'
      }
    };
  },
  computed: {
    index() {
      if (this.windowWidth >= 640) {
        return 0;
      } else if (this.selectedCity) {
        return 4;
      } else if (this.selectedCountry) {
        return 3;
      } else if (this.selectedRegion) {
        return 2;
      } else if (this.locationTypeId) {
        return 1;
      } else {
        return 0;
      }
    },
    title() {
      if (this.index == 1) {
        var type = this.locationTypes.find(
          (locationType) => locationType.id == this.locationTypeId
        );
        if (type) {
          return type.name;
        }
      } else if (this.index == 2) {
        const region = this.regions.find((r) => r.id === this.selectedRegion);

        return region.name === "region.unknown"
          ? this.$t(`common.placeholders.${region.name}`)
          : region.name;
      } else if (this.index == 3) {
        return this.selectedCountry === "country.unknown"
          ? this.$t(`common.placeholders.${this.selectedCountry}`)
          : this.selectedCountry;
      } else if (this.index == 4) {
        return this.selectedCity === "city.unknown"
          ? this.$t(`common.placeholders.${this.selectedCity}`)
          : this.selectedCity;
      }
      return "locations";
    },
    searchableLocations() {
      if (!this.searchLocations) return [];

      return this.searchLocations
        .filter((x) =>
          x.customName && x.customName.trim() !== '' ||
          x.locationNumber && x.locationNumber.trim() !== ''
        )
        .map((x) => ({
          id: x.id,
          name: x.customName && x.customName.trim() !== ''
            ? x.customName
            : x.locationNumber,
          subtitle: x.customName && x.customName.trim() !== ''
            ? x.locationNumber
            : '',
          searchText: `${x.customName ?? ''}${x.locationNumber ?? ''}`
        }))
    }
  },
  methods: {
    historyVisit(route) {
      SetHistory(PageKeys.Location, "executive.locations", {
        locationTypeId: this.locationTypeId,
        regionId: this.selectedRegion,
        country: this.selectedCountry,
        city: this.selectedCity,
      });
      this.$inertia.visit(route);
    },
    handleLocationSearchSelected(locationId) {
      const routeParams = {
        locationId: locationId,
      };

      if (this.locationTypeId)
        routeParams["locationTypeId"] = this.locationTypeId;

      if (this.selectedRegion) routeParams["regionId"] = this.selectedRegion;

      if (this.selectedCountry) routeParams["country"] = this.selectedCountry;

      if (this.selectedCity) routeParams["city"] = this.selectedCity;

      this.$inertia.get(
        this.route("executive.locations", routeParams),
        {},
        {
          preserveState: true,
          replace: true,
        }
      );
    },
    getLocationName(location) {
      const customName = location.customName?.trim() ?? "";

      if (customName !== "") {
        return customName;
      }

      if (location.addressLine1) {
        return location.addressLine1;
      }

      return this.$t("common.placeholders.street.unknown")
    }
  },
  watch: {
    locationId: function(value) {
      this.handleLocationSearchSelected(value);
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>
<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
.location-search-container:deep(.multiselect-search) {
  color: black;
}
.location-search-container:deep(.multiselect-clear) {
  padding-right: 0.25rem;
}
</style>
