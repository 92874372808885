<template>
  <div class="relative flex flex-1">
    <div class="flex-1 flex flex-col px-4 py-4 bg-gray-200 w-100-vw">
      <span class="text-lg text-gray-500 font-bold mx-6 inline-block uppercase">
        {{ $t("pages.exportLocations.countries") }}
      </span>
      <vue-multiselect
        :modelValue="selectedCountries"
        :options="countries"
        :searchable="true"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :showLabels="false"
        @update:modelValue="toggleCountrySelections"
        class="mb-2"
      />

      <span class="text-lg text-gray-500 font-bold mx-6 inline-block uppercase">
        {{ $t("pages.exportLocations.locations") }}
      </span>
      <vue-multiselect
        v-model="selectedLocations"
        track-by="id"
        label="name"
        :options="locations"
        :searchable="true"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :showLabels="false"
        class="mb-2"
      />
      <button
        class="mt-6 py-2 uppercase text-white w-full mt-auto"
        :class="isFormFilled ? 'bg-brand7' : 'bg-gray-300'"
        :disabled="!isFormFilled"
        @click="submit"
      >
        {{ $t("pages.exportLocations.download") }}
      </button>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import VueMultiselect from "vue-multiselect";
import { objectToFormData } from "@/util/formData";
import route from "@/vendor/ziggy";

export default defineComponent({
  components: { VueMultiselect },
  props: {
    locations: Array,
    countries: Array,
  },
  setup(props) {
    const selectedCountries = computed(() =>
      props.countries.filter((c) =>
        props.locations
          .filter((l) => l.country === c)
          .every((l) => selectedLocations.value.includes(l))
      )
    );

    const toggleCountrySelections = (countryList) => {
      const isRemoval = selectedCountries.value.length > countryList.length;

      const selectedCountry = isRemoval
        ? selectedCountries.value.find((c) => !countryList.includes(c))
        : countryList.find((c) => !selectedCountries.value.includes(c));

      if (isRemoval) {
        selectedLocations.value = selectedLocations.value.filter(
          (l) => l.country !== selectedCountry
        );
      } else {
        const otherLocations = selectedLocations.value.filter(
          (l) => l.country !== selectedCountry
        );
        selectedLocations.value = otherLocations.concat(
          props.locations.filter((l) => l.country === selectedCountry)
        );
      }
    };

    const selectedLocations = ref(props.locations);

    const isFormFilled = computed(() => selectedLocations.value.length > 0);

    // Based on: https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
    const getFileNameFromHeader = (header) => {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    };

    const submit = () => {
      fetch(route("executive.locations.export.execute"), {
        method: "POST",
        body: objectToFormData({
          locationIds: selectedLocations.value.map((l) => l.id),
        }),
      })
        .then(async (res) => ({
          fileName: getFileNameFromHeader(
            res.headers.get("content-disposition")
          ),
          contentType: res.headers.get("content-type"),
          blob: await res.blob(),
        }))
        .then((fileData) => {
          const blob = new Blob([fileData.blob], {
            type: fileData.contentType,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            const blobUrl = URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileData.fileName;
            link.click();

            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 250);
          }
        });
    };

    return {
      selectedCountries,
      toggleCountrySelections,
      selectedLocations,
      isFormFilled,
      submit,
    };
  },
});
</script>
<style>
.w-100-vw {
  width: 100vw;
}
</style>
