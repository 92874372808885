<template>
  <div class="flex justify-center flex-col my-auto">
    <h1 class="text-xl mt-4 font-bold uppercase text-white">
      {{ $t("pages.forgotPassword.title") }}
    </h1>
    <p class="mt-4 break-normal leading-snug text-white">
      {{ $t("pages.forgotPassword.description") }}
    </p>
    <form class="pb-6" @submit.prevent="form.post(route('password.email'))">
      <v-input
        type="email"
        v-model="form.email"
        :placeholder="$t('components.form.enterEmail')"
        :error-message="form.errors.email"
        class="mt-8 rounded-sm h-14 placeholder-prime_grey_dark px-5"
      />
      <v-button
        type="submit"
        class="mt-8"
        :loading="form.processing"
        :disabled="form.processing"
        >{{ $t("pages.forgotPassword.submitButton") }}</v-button
      >
    </form>
  </div>
</template>
<script>
import { useForm } from "@inertiajs/inertia-vue3";
import VButton from "@/components/VButton.vue";
import AuthLayout from "./AuthLayout";

export default {
  components: { VButton },
  layout: AuthLayout,
  setup() {
    const form = useForm({
      email: null,
    });

    return { form };
  },
};
</script>
