<template>
  <div class="bg-white flex justify-center flex-col mt-auto pt-9">
    <h1 class="text-2xl text-center uppercase text-brand8">
      {{ $t("pages.resetPassword.title") }}
    </h1>
    <form
      class="px-3 pb-6 sm:px-10"
      @submit.prevent="form.post(route('password.update'))"
    >
      <input
        type="text"
        autocomplete="username email"
        class="hidden"
        :value="form.email"
      />
      <v-input
        type="password"
        v-model="form.password"
        :placeholder="$t('pages.resetPassword.password')"
        autocomplete="new-password"
        :error-message="form.errors.password"
        autofocus
        class="mt-3"
      />
      <v-input
        type="password"
        v-model="form.confirmPassword"
        :placeholder="$t('pages.resetPassword.confirmPassword')"
        autocomplete="new-password"
        :error-message="form.errors.confirmPassword"
        class="mt-3"
      />
      <div
        v-if="flash.error"
        class="text-error text-sm mt-2 whitespace-pre-line"
      >
        {{ flash.error }}
      </div>
      <v-button
        type="submit"
        class="bg-brand4 mt-6"
        :loading="form.processing"
        :disabled="form.processing"
        >{{ $t("pages.resetPassword.submitButton") }}</v-button
      >
    </form>
  </div>
</template>
<script>
import { useForm } from "@inertiajs/inertia-vue3";
import VButton from "@/components/VButton.vue";
import AuthLayout from "./AuthLayout";

export default {
  components: { VButton },
  layout: AuthLayout,
  props: {
    token: String,
    email: String,
    flash: Object,
  },
  setup(props) {
    const form = useForm({
      token: props.token,
      email: props.email,
      password: "",
      confirmPassword: "",
    });

    return { form, flash2: props.flash };
  },
};
</script>
