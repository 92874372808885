<template>
  <div class="bg-white flex justify-center flex-col mt-auto pt-9">
    <h1 class="text-2xl text-center uppercase text-brand8">
      {{ $t("pages.resetPasswordConfirmation.title") }}
    </h1>
    <div class="px-3 pb-6 sm:px-10">
      {{ $t("pages.resetPasswordConfirmation.description") }}
      <a asp-page="./Login"
        ><inertia-link :href="route('login')">{{
          $t("pages.resetPasswordConfirmation.loginLink")
        }}</inertia-link></a
      >
    </div>
  </div>
</template>
<script>
import AuthLayout from "./AuthLayout";

export default {
  layout: AuthLayout,
};
</script>
