export function isSlaActive(slaContent) {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let startsAt = null;
  if (slaContent.startsAt) {
    startsAt = new Date(slaContent.startsAt);
    startsAt.setHours(0,0,0,0);
  }

  let endsAt = null;
  if (slaContent.endsAt) {
    endsAt = new Date(slaContent.endsAt);
    endsAt.setHours(0,0,0,0);
  }

  if (startsAt === null && endsAt === null) {
    return false;
  } else if (startsAt !== null && endsAt === null) {
    return startsAt <= currentDate;
  } else if (startsAt === null && endsAt !== null) {
    return currentDate <= endsAt;
  } else {
    return startsAt <= currentDate && currentDate <= endsAt;
  }
};