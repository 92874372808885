<template>
  <app-layout :title="$t('pages.home.organizationalDocuments')">
    <div class="overflow-hidden">
      <h5 class="text-h5 text-green-2 font-bold mx-7 mt-2 mb-2">
        {{ organization.name }}
      </h5>
      <div class="flex flex-wrap items-start w-full">
        <template v-for="(file, index) in indexedOrganizationalFiles">
          <div
            v-if="file.imageIndex != null"
            :key="file.id"
            class="flex flex-col items-center w-1/2 p-4 sm:w-28 has-tooltip"
          >
            <img
              :src="
                route('api.files.content', {
                  id: file.id,
                  filename: file.originalName,
                  thumbnail: !!file.thumbnailUrl
                })
              "
              class="fill-current font-black inline-block cursor-pointer w-24 h-24 sm:w-16 sm:h-16"
              @click="
                viewImageObject(indexedOrganizationalFiles, file)
              "
            />
            <span
              class="tooltip rounded shadow-xl p-1 bg-black text-white -mt-8"
              :class="index === 0 ? 'left-0' : ''"
              >{{ file.originalName }}</span
            >
          </div>
          <file
            v-else
            :key="file.id"
            :file="file"
            hide-file-name
            show-tooltip
          />
        </template>
      </div>
    </div>
  </app-layout>
</template>
<script>
import { PageKeys, GetHistory } from "@/util/navigationHistory.js";
import AppLayout from "@/Pages/AppLayout";
import File from "@/components/File.vue";
import "@/assets/css/viewer.css";
import { api as viewerApi } from "v-viewer";

export default {
  components: { AppLayout, File },
  props: {
    organizationalFiles: Array,
  },
  data() {
    return {
      PageKeys,
      returnHref: null,
      organization: this.$page.props.auth.organization,
      desktopView: window.innerWidth >= 640,
    };
  },
  computed: {
    indexedOrganizationalFiles() {
      return this.addIndexForImageViewer(this.organizationalFiles);
    }
  },
  mounted() {
    this.returnHref = GetHistory(PageKeys.SolutionTemplate);
    window.addEventListener("resize", () => {
      this.desktopView = window.innerWidth >= 640;
    });
  },
  methods: {
    addIndexForImageViewer(files) {
      let index = 0;
      return files.map((obj) => {
        let newObj = {
          ...obj,
          imageIndex: this.isImage(obj) ? index : null,
          url: this.getRoute(obj),
          alt: obj.originalName,
        };
        index++;
        return newObj;
      });
    },
    getRoute(file) {
      return this.route('api.files.content', {
        id: file.id,
        filename: file.originalName
      })
    },
    isImage(file) {
      var extension = file.extension.toLowerCase();
      if ([".png", ".jpg", ".jpeg"].includes(extension)) {
        return true;
      }
      return false;
    },
    viewImageObject(filesWithImageIndex, file) {
      let imageArray = filesWithImageIndex.filter((x) => x.imageIndex != null);
      viewerApi({
        options: {
          toolbar: true,
          url: "url",
          initialViewIndex: file.imageIndex,
          title: (image) => {
            return image.alt;
          },
        },
        images: imageArray,
      });
    },
  },
};
</script>
