export default {
    container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer rounded bg-white text-base leading-snug outline-none focus:shadow-none',
    option: 'uppercase flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
    optionPointed: 'bg-green-2 text-white',
    optionSelected: 'bg-prime-green text-white',
    optionSelectedPointed: 'bg-prime-green text-white',
    noOptions: 'py-2 px-3 text-prime-green bg-white text-left',
    noResults: 'py-2 px-3 text-prime-green bg-white text-left',
    placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-prime-green uppercase',
    spacer: 'h-9 py-px box-content',
    dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-prime-white -mt-px overflow-y-auto z-50 bg-white flex flex-col rounded-b',
    dropdownHidden: 'hidden',
    caret: 'bg-multiselect-caret bg-center bg-no-repeat w-10 h-10 box-content mx-2 relative z-10 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
    caretOpen: 'rotate-180 pointer-events-auto',
}