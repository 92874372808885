export const PageKeys = {
    Location: "Location",
    Support: "Support",
    SolutionTemplate: "SolutionTemplate",
}

export function GetHistory(pageKey) {
    if (sessionStorage.getItem(pageKey)) {
        try {
            return JSON.parse(sessionStorage.getItem(pageKey));
        } catch (e) {
            sessionStorage.removeItem(pageKey);
            return null;
        }
    }
}

export function SetHistory(pageKey, path, properties) {
    var returnHref = { path, properties }
    var parsed = JSON.stringify(returnHref);
    sessionStorage.setItem(pageKey, parsed);
}