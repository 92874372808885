<template>
  <div v-if="scannerActive" class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {{ scanHeading }}
              </h3>
              <div class="mt-2">
                <qr-stream :camera="camera" @init="onInit" @decode="onDecode" />
                <qr-capture
                  :camera="camera"
                  v-if="noStreamApiSupport"
                  @decode="onDecode"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="cancelScan"
          >
            Cancel scan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { QrStream, QrCapture } from "vue3-qr-reader";

export default {
  components: { QrStream, QrCapture },
  emits: ["scan-finished", "scan-cancelled", "scan-error"],
  props: {
    scannerActive: Boolean,
    scanHeading: String,
  },
  watch: {
    scannerActive: function () {
      this.camera = "auto";
    },
  },
  data() {
    return {
      camera: "off",
      noStreamApiSupport: false,
    };
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
          this.noStreamApiSupport = true;
        } else {
          this.camera = "off";
          this.$emit("scan-error", error.name);
        }
      }
    },
    cancelScan() {
      this.camera = "off";
      this.$emit("scan-cancelled");
    },
    onDecode(result) {
      this.camera = "off";
      this.$emit("scan-finished", result);
    },
  },
};
</script>
