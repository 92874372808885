<template>
  <div class="bg-white flex py-2 pl-3 pr-2">
    <inline-svg
      :src="require('@/assets/search.svg')"
      class="fill-current text-gray-600 h-8 mr-3"
    />
    <input
      v-bind="$attrs"
      type="text"
      class="w-full text-gray-700 outline-none"
      :value="modelValue"
      @input="(e) => $emit('update:modelValue', e.target.value)"
    />
  </div>
</template>
<script>
export default {
  inheritattrs: false,
  props: {
    modelValue: String,
  },
  emits: ["update:modelValue"],
};
</script>
