<template>
  <div class="mt-2">
    <div class="space-y-2">
      <div
        v-for="slaContent in slaContents"
        :key="slaContent.id"
        class="border-b pb-4 border-gray-300"
      >
        <div class="text-lg text-brand2">SLA</div>
        <div class="flex justify-between mb-2">
          <i18n-t
            :keypath="
              isSlaActive(slaContent)
                ? 'components.slaContent.statusActive'
                : 'components.slaContent.statusInactive'
            "
            tag="div"
            class="text-xs inline-block"
          >
            {{ formatDate(slaContent.endsAt) }}
          </i18n-t>
          <inline-svg
            :src="require('@/assets/circle.svg')"
            class="w-4 inline-block fill-current"
            :class="isSlaActive(slaContent) ? 'text-green-600' : 'text-red-600'"
          />
        </div>
        <div class="text-xs mb-4" v-html="slaContent.description" />
        <div v-for="file in slaContent.files" :key="file.id">
          <div class="flex justify-between text-sm">
            <div class="break-all">
              {{ file.name }}
            </div>
            <a
              class="text-brand7 font-bold ml-4"
              target="_blank"
              :href="
                route('api.files.content', {
                  id: file.id,
                  filename: file.originalName
                })
              "
              >{{ $t("pages.mylocation.download") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isSlaActive } from "@/util/slaHelpers";

export default {
  props: {
    slaContents: Array,
  },
  methods: {
    isSlaActive,
    formatDate(date) {
      if (!date) return this.$t("common.placeholders.unknown");

      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
