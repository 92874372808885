<template>
  <div class="flex-1 px-4 py-4 bg-gray-200">
    <div class="px-6 text-lg text-gray-500 font-semibold flex flex-col">
      <span class="text-brand7 font-bold">{{ supportCase.referenceKey }}</span>
      <span>
        {{ supportCase.name }}
      </span>
      <div class="mt-6 space-y-2">
        <data-line
          :heading="$t('pages.supportcase.status')"
          :value="$t(`common.supportCaseStatuses.${supportCase.status}`)"
        />
        <data-line
          :heading="$t('pages.supportcase.createdat')"
          :value="
            formatDate(supportCase.manualStartedAt ?? supportCase.createdAt)
          "
        />
        <data-line
          v-if="supportCase.location"
          :heading="$t('pages.supportcase.locationname')"
          :value="supportCase.location.name"
        />
        <div v-if="supportCase.location" class="flex leading-5 space-x-2">
          <span class="break-words min-w-1/2">
            {{ $t("pages.supportcase.locationaddress") }}
          </span>
          <div class="flex flex-col min-w-1/2 break-all whitespace-pre-line">
            <span>
              {{
                supportCase.location.addressLine1 ||
                $t("pages.supportcase.location.unknownstreet")
              }}
            </span>
            <span>{{ supportCase.location.addressLine2 }}</span>
            <span>
              {{
                supportCase.location.state ||
                $t("pages.supportcase.location.unknownregion")
              }}
            </span>
            <span>
              {{ supportCase.location.postalCode }}
              {{ supportCase.location.postalCode && " " }}
              {{
                supportCase.location.city ||
                $t("pages.supportcase.location.unknowncity")
              }}
            </span>
            <span>
              {{
                supportCase.location.country ||
                $t("pages.supportcase.location.unknowncountry")
              }}
            </span>
          </div>
        </div>
        <data-line
          v-if="supportCase.solution"
          :heading="$t('pages.supportcase.solutionname')"
          :value="supportCase.solution.name"
        />
        <data-line
          :heading="$t('pages.supportcase.username')"
          :value="
            (supportCase.user && supportCase.user.name) ||
            supportCase.fallbackEmail
          "
        />
      </div>
    </div>
    <span
      class="text-xl text-gray-500 font-semibold mx-6 mt-6 inline-block uppercase"
    >
      {{ $t("pages.supportcase.supportchat") }}
    </span>
    <div class="flex flex-col mt-1">
      <transition-expand>
        <div>
          <div
            v-for="(message, index) in supportChatMessages"
            :key="message.id"
            :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
            class="px-4 py-2 text-gray-500"
          >
            <div class="text-md font-bold flex justify-between">
              <span class="w-1/2 break-all">{{ message.authorName }}</span>
              <span class="w-1/2 text-right whitespace-pre-line">{{
                formatDate(message.createdAt)
              }}</span>
            </div>
            <span class="text-lg font-medium whitespace-pre-wrap break-all">{{
              message.message
            }}</span>
            <div v-if="!!message.files.length" class="text-md flex flex-col">
              <span class="font-bold">Attachments:</span>
              <a
                v-for="file in message.files"
                :key="file.id"
                :href="
                  route('api.files.content', {
                    id: file.id,
                    filename: file.originalName
                  })
                "
                target="_blank"
                class="max-h-7 overflow-x-hidden overflow-ellipsis underline leading-7"
              >
                {{ file.originalName }}
              </a>
            </div>
          </div>
        </div>
      </transition-expand>
      <button
        class="bg-gray-300 text-gray-500 font-bold relative py-2 uppercase"
        @click="isChatExpanded = !isChatExpanded"
      >
        {{
          isChatExpanded
            ? $t("pages.supportcase.closechat")
            : $t("pages.supportcase.openchat")
        }}
        <inline-svg
          :src="
            isChatExpanded
              ? require('@/assets/arrow-up.svg')
              : require('@/assets/arrow-down.svg')
          "
          class="fill-current h-8 absolute right-1 top-1 bottom-1"
        />
      </button>
    </div>
    <span
      class="text-xl text-gray-500 font-semibold mx-6 mt-6 inline-block uppercase"
    >
      {{
        isSupportCaseClosed
          ? $t("pages.supportcase.problemnotsolved")
          : $t("pages.supportcase.writenewmessage")
      }}
    </span>
    <div class="flex flex-col mt-1">
      <textarea
        v-model="messageForm.message"
        :placeholder="$t('components.supportcasemessage.messageplaceholder')"
        rows="3"
        class="outline-none text-gray-500 px-4 py-4 resize-none"
      />
      <file-input
        :files="messageForm.files"
        @files-added="(files) => messageForm.files.push(...files)"
        @files-cleared="() => (messageForm.files = [])"
      />
      <button
        class="text-white font-bold py-2 uppercase"
        :class="messageSubmitButtonColor"
        :disabled="isMessageFormDisabled"
        @click="sendMessage"
      >
        {{
          isSupportCaseClosed
            ? $t("components.supportcasemessage.reopencase")
            : $t("components.supportcasemessage.sendmessage")
        }}
      </button>
    </div>
    <div class="flex flex-col">
      <button
        v-if="!isSupportCaseClosed"
        class="mt-12 py-2 uppercase text-white"
        :class="isCloseCaseButtonDisabled ? 'bg-gray-300' : 'bg-success'"
        :disabled="isCloseCaseButtonDisabled"
        @click="closeCase"
      >
        {{ $t("pages.supportcase.closecase") }}
      </button>
    </div>
  </div>
</template>
<script>
import TransitionExpand from "@/components/TransitionExpand";
import DataLine from "@/components/DataLine";
import { objectToFormData } from "@/util/formData";
import FileInput from "@/components/FileInput";

export default {
  components: { TransitionExpand, DataLine, FileInput },
  props: {
    supportCase: Object,
  },
  data() {
    return {
      isChatExpanded: false,
      messageForm: this.$inertia.form({
        message: null,
        files: [],
      }),
      closeCaseForm: this.$inertia.form(),
    };
  },
  methods: {
    formatDate(date) {
      const dateObject = new Date(date);

      return `${dateObject.toLocaleDateString()}\n${dateObject.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      )}`;
    },
    sendMessage() {
      if (this.isSupportCaseClosed) {
        let confirmation = confirm(
          this.$t("components.supportcasemessage.confirmreopen")
        );

        if (!confirmation) return;
      }

      this.messageForm
        .transform((data) =>
          objectToFormData({ ...data, supportCaseId: this.supportCase.id })
        )
        .post(this.route("my-support-case-messages.store"), {
          preserveScroll: true,
        });

      this.messageForm.message = null;
      this.messageForm.files = [];
    },
    closeCase() {
      let confirmation = confirm(this.$t("pages.supportcase.confirmclose"));

      if (!confirmation) return;

      this.closeCaseForm
        .transform(() => objectToFormData({ status: "Closed" }))
        .put(this.route("support-cases.update", this.supportCase.id), {
          preserveScroll: true,
        });
    },
  },
  computed: {
    supportChatMessages() {
      const messages = this.supportCase.messages;

      if (!messages?.length) return [];

      if (!this.isChatExpanded) {
        const chatLength = messages.length;

        return [messages[chatLength - 1]];
      }

      return messages;
    },
    isSupportCaseClosed() {
      return this.supportCase.status === "Closed";
    },
    isMessageFormDisabled() {
      return (
        this.messageForm.processing ||
        !this.messageForm.message ||
        this.closeCaseForm.processing
      );
    },
    messageSubmitButtonColor() {
      if (this.isMessageFormDisabled) return "bg-gray-300";
      if (this.isSupportCaseClosed) return "bg-warning";

      return "bg-brand7";
    },
    isCloseCaseButtonDisabled() {
      return this.closeCaseForm.processing || this.messageForm.processing;
    },
  },
};
</script>
