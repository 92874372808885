<template>
  <app-layout
    :title="$t('pages.executive.locationDocuments')"
    :returnHref="{
      path: 'executive.locations.show',
      properties: { id: location.id },
    }"
  >
    <div class="overflow-hidden">
      <h5 class="text-h5 text-green-2 font-bold mx-7 mt-2 mb-6">
        {{ location.locationType.name }}
      </h5>
      <div
        v-for="content in contents"
        :key="content.id"
        class="flex flex-col items-center sm:items-start mx-7 px-5 py-5 font-bold text-prime-green mb-8 bg-white rounded border border-prime-grey"
      >
        <h1
          class="uppercase text-b1 text-white bg-green-2 font-bold w-full px-4"
        >
          {{ content.informationType.name }}
        </h1>
        <div
          v-if="content.description"
          class="my-4"
          v-html="content.description"
        />
        <div class="flex flex-wrap items-start w-full">
          <template v-for="file in addIndexForImageViewer(content.files)">
            <div
              v-if="file.imageIndex != null"
              :key="file.id"
              class="flex flex-col items-center w-1/2 p-4 sm:w-28 has-tooltip"
            >
              <img
                :src="
                  route('api.files.content', {
                    id: file.id,
                    filename: file.originalName,
                    thumbnail: !!file.thumbnailUrl
                  })
                "
                class="fill-current text-green-2 h-16 font-black inline-block cursor-pointer w-16 h-16"
                @click="
                  viewImageObject(addIndexForImageViewer(content.files), file)
                "
              />
              <span
                class="tooltip rounded shadow-xl p-1 bg-black text-white -mt-8"
                >{{ file.originalName }}</span
              >
            </div>
            <file
              v-else
              :key="file.id"
              :file="file"
              hide-file-name
              show-tooltip
            />
          </template>
        </div>
      </div>
      <div
        v-for="content in typeContents"
        :key="content.id"
        class="flex flex-col items-center sm:items-start mx-7 px-5 py-5 font-bold text-prime-green mb-8 bg-white rounded border border-prime-grey"
      >
        <h1
          class="uppercase text-b1 text-white bg-green-2 font-bold w-full px-4"
        >
          {{ content.informationType.name }}
        </h1>
        <div
          v-if="content.description"
          class="my-4"
          v-html="content.description"
        />
        <div class="flex flex-wrap items-start w-full">
          <template v-for="file in addIndexForImageViewer(content.files)">
            <div
              v-if="file.imageIndex != null"
              :key="file.id"
              class="flex flex-col items-center w-1/2 p-4 sm:w-28 has-tooltip"
            >
              <img
                :src="
                  route('api.files.content', {
                    id: file.id,
                    filename: file.originalName,
                    thumbnail: !!file.thumbnailUrl
                  })
                "
                class="fill-current text-green-2 h-16 font-black inline-block cursor-pointer w-16 h-16"
                @click="
                  viewImageObject(addIndexForImageViewer(content.files), file)
                "
              />
              <span
                class="tooltip rounded shadow-xl p-1 bg-black text-white -mt-8"
                >{{ file.originalName }}</span
              >
            </div>
            <file
              v-else
              :key="file.id"
              :file="file"
              hide-file-name
              show-tooltip
            />
          </template>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from "@/Pages/AppLayout";
import File from "@/components/File.vue";
import "@/assets/css/viewer.css";
import { api as viewerApi } from "v-viewer";

export default {
  components: { AppLayout, File },
  props: ["location"],
  data() {
    return {
      organization: this.$page.props.auth.organization,
      desktopView: window.innerWidth >= 640,
    };
  },
  computed: {
    contents() {
      console.log(this.location);
      return this.location?.contents ?? [];
    },
    typeContents() {
      return this.location?.locationType?.contents ?? [];
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.desktopView = window.innerWidth >= 640;
    });
  },
  methods: {
    addIndexForImageViewer(files) {
      let index = 0;
      return files.map((obj) => {
        let newObj = {
          ...obj,
          imageIndex: this.isImage(obj) ? index : null,
          url: this.getRoute(obj),
          alt: obj.originalName,
        };
        index++;
        return newObj;
      });
    },
    getRoute(file) {
      return this.route('api.files.content', {
        id: file.id,
        filename: file.originalName
      })
    },
    isImage(file) {
      var extension = file.extension.toLowerCase();
      if ([".png", ".jpg", ".jpeg"].includes(extension)) {
        return true;
      }
      return false;
    },
    viewImageObject(filesWithImageIndex, file) {
      let imageArray = filesWithImageIndex.filter((x) => x.imageIndex != null);
      viewerApi({
        options: {
          toolbar: true,
          url: "url",
          initialViewIndex: file.imageIndex,
          title: (image) => {
            return image.alt;
          },
        },
        images: imageArray,
      });
    },
  },
};
</script>
