<template>
  <div  ref="container" class="transition-expand" :style="style">
    <transition
      name="transition-fade"
      :duration="duration"
      @enter="enter"
      @leave="leave"
      @enterCancelled="clearHeight"
      @leaveCancelled="clearHeight"
      @afterEnter="clearHeight"
      @afterLeave="clearHeight"
    >
      <slot />
    </transition>
  </div>
</template>

<script>
export default {
  name: "TransitionExpand",
  props: {
    duration: {
      type: String,
      default: "200",
    },
  },
  data() {
    return {
      startHeight: 0,
      height: null,
    };
  },
  computed: {
    container() {
      return this.$refs.container;
    },
    style() {
      const { duration, height } = this;
      return {
        "--expand-duration": `${duration}ms`,
        height: height !== null ? height + "px" : null,
      };
    },
  },
  methods: {
    enter() {
      const { container } = this;
      this.height = null;
      const endHeight = container.clientHeight;
      this.height = this.startHeight;
      setTimeout(() => {
        this.height = endHeight;
      });
    },
    leave(el) {
      const { container } = this;
      this.height = null;
      const [newEl] = Array.from(container.children).filter(
        (child) => child !== el
      );
      if (newEl) {
        newEl.classList.add("transition-expand__detached");
      }
      this.startHeight = container.clientHeight;
      if (newEl) {
        newEl.classList.remove("transition-expand__detached");
      }
      el.classList.add("transition-expand__detached");
      const endHeight = container.clientHeight;
      this.height = this.startHeight;
      if (!newEl) {
        setTimeout(() => {
          this.height = endHeight;
        });
      }
    },
    clearHeight() {
      this.startHeight = 0;
      this.height = null;
    },
  },
};
</script>

<style>
.transition-fade-enter-active,
.transition-fade-leave-active {
  transition: 0.2s;
  transition-property: opacity;
}
.transition-fade-enter,
.transition-fade-leave-to {
  opacity: 0;
}
.transition-expand {
  overflow: hidden;
  will-change: height;
  position: relative;
  transition: height var(--expand-duration);
}
.transition-expand__detached {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
</style>
