<template>
  <app-layout
    :title="title && !desktopView ? title : $t('pages.mylocation.support')"
    :return-href="getReturnHref"
  >
    <div class="overflow-hidden flex-grow flex flex-col">
      <div class="flex sm:justify-between w-screen sm:px-5 flex-grow">
        <div
          v-if="showMenu || desktopView"
          class="flex flex-col w-screen sm:w-1/5 px-5 sm:pl-5"
        >
          <inertia-link replace :href="route('support-cases.create')">
            <v-button
              type="submit"
              class="mb-4"
              :class="{
                'bg-prime-green-dark hover:bg-prime-green-dark': menuIndex == 0,
              }"
              @click="buttonClicked"
              >{{ $t("components.supportLayout.newcase") }}</v-button
            >
          </inertia-link>
          <inertia-link replace :href="route('document-requests.create')">
            <v-button
              type="submit"
              class="mb-4"
              :class="{
                'bg-prime-green-dark hover:bg-prime-green-dark': menuIndex == 1,
              }"
              @click="buttonClicked"
              >{{ $t("components.supportLayout.requestDocument") }}</v-button
            >
          </inertia-link>
          <inertia-link replace :href="route('feature-requests.create')">
            <v-button
              type="submit"
              class="mb-4"
              :class="{
                'bg-prime-green-dark hover:bg-prime-green-dark': menuIndex == 2,
              }"
              @click="buttonClicked"
              >{{ $t("components.supportLayout.requestFeature") }}</v-button
            >
          </inertia-link>
          <inertia-link replace :href="route('executive.support-cases')">
            <v-button 
              type="submit"
              class="mb-4"
              :class="{
                'bg-prime-green-dark hover:bg-prime-green-dark': menuIndex == 3,
              }"
              @click="buttonClicked"
              >{{
                $t("components.supportLayout.existingSupportCases")
              }}</v-button
            >
          </inertia-link>
          <inertia-link replace :href="route('executive.support-cases.map')">
            <v-button
              type="submit"
              class="mb-4"
              :class="{
                'bg-prime-green-dark hover:bg-prime-green-dark': menuIndex == 4,
              }"
              >{{ $t("components.supportLayout.worldMap") }}</v-button
            >
          </inertia-link>
          <a
            v-if="organization.contentManagementSystemUrl"
            :href="organization.contentManagementSystemUrl"
            target="_blank"
          >
            <v-button class="mb-4">{{
              $t("components.supportLayout.cmsUrl")
            }}</v-button>
          </a>
        </div>
        <div
          v-if="!showMenu || desktopView"
          class="flex flex-col w-screen sm:w-4/5 px-5 sm:pl-5"
        >
          <slot />
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import AppLayout from "../Pages/AppLayout";
import { isSlaActive } from "@/util/slaHelpers";
import { PageKeys, GetHistory } from "@/util/navigationHistory.js";
import eventBus from "../eventBus";

export default {
  components: { AppLayout },
  props: {
    title: String,
    showMenu: Boolean,
    menuIndex: Number,
    returnHref: String,
  },
  data() {
    return {
      returnHistory: null,
      organization: this.$page.props.auth.organization,
      desktopView: window.innerWidth >= 640,
    };
  },
  computed: {
    getReturnHref() {
      if (this.returnHref) {
        return { path: this.returnHref, properties: {} };
      } else if (this.showMenu || this.desktopView) {
        if (this.returnHistory) {
          return this.returnHistory;
        }
        return { path: "home", properties: {} };
      } else {
        return {
          path: "executive.support-cases.menu",
          properties: {},
          replace: true,
        };
      }
    },
  },
  mounted() {
    this.returnHistory = GetHistory(PageKeys.Support);
    window.addEventListener("resize", () => {
      this.desktopView = window.innerWidth >= 640;
    });
  },
  methods: {
    isSlaActive,
    isAnySlaActive(slaContents) {
      return slaContents.some((slaContent) => isSlaActive(slaContent));
    },
    isAnySupportCaseActive(supportCases) {
      return supportCases.some((supportCase) => supportCase.status != "Closed");
    },
    buttonClicked() {
      eventBus.$emit("stopMapRefresh");
    },
  },
};
</script>
