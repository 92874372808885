<template>
  <div>
    <menu-group
      v-for="(item, index) in items"
      :key="item.id"
      :bg="index % 2 == 0 ? 'bg-brand4' : 'bg-brand3'"
      :is-active="isLocationActive(item)"
      :active-link="
        route('executive.locations', {
          locationTypeId: item.locationTypeId,
          country: item.country,
        })
      "
      :inactive-link="route('executive.locations.show', item.id)"
      :only="isLocationActive(item) ? [] : ['location', 'solutions']"
    >
      <template #text>
        <div class="pl-16 normal-case">
          {{ !item.city ? $t("common.placeholders.city.unknown") : item.city }}
          /
          {{
            !item.addressLine1
              ? $t("common.placeholders.street.unknown")
              : item.addressLine1
          }}
        </div>
      </template>
      <template #content>
        <menu-group
          v-for="(solution, solutionIndex) in solutions"
          :key="solution.id"
          :bg="solutionIndex % 2 == 0 ? 'bg-brand2' : 'bg-brand1'"
          :is-active="isSolutionActive(solution)"
          :active-link="route('executive.locations.show', item.id)"
          :inactive-link="
            route('executive.locations.solutions.show', solution.id)
          "
          :only="isSolutionActive(solution) ? [] : ['solution']"
        >
          <template #text>
            <div class="pl-18 normal-case">
              {{ solution.solutionTemplate.name }} ({{ solution.quantity }})
            </div>
          </template>
          <template #content>
            <solution :item="selectedSolution" />
          </template>
        </menu-group>
        <menu-group
          :bg="
            solutions && solutions.length % 2 == 0 ? 'bg-brand2' : 'bg-brand1'
          "
          :is-active="isLocationDocumentsActive(item)"
          :active-link="route('executive.locations.show', item.id)"
          :inactive-link="
            route('executive.locations.show', {
              id: item.id,
              showDocuments: true,
            })
          "
          :only="isLocationActive(item) ? [] : ['location']"
        >
          <template #text>
            <div class="pl-18">
              <inline-svg
                :src="require('@/assets/file-document-outline.svg')"
                class="fill-current text-white h-10 ml-auto mr-1 inline-block"
              />
              {{ $t("pages.executive.locationDocuments") }}
            </div>
          </template>
          <template #content>
            <div
              class="bg-gray-200 pr-6 pl-4 pt-4 pb-8 space-y-3 text-gray-500 flex-auto"
            >
              <div>
                <h1 class="text-xl text-brand7 font-medium">
                  {{ $t("pages.mylocation.availableContent") }}
                </h1>
                <sla-contents
                  v-if="selected.slaContents"
                  :sla-contents="selected.slaContents"
                />
                <contents
                  v-if="selected.contents"
                  :contents="selected.contents"
                />
                <contents
                  v-if="selected.locationType.contents"
                  :contents="selected.locationType.contents"
                />
              </div>
              <div class="mt-6">
                <a
                  class="text-white font-bold py-2 uppercase bg-brand7 w-full block text-center"
                  :href="
                    route('document-requests.create', {
                      locationId: selected.id,
                    })
                  "
                >
                  {{ $t("pages.home.requestDocuments") }}
                </a>
              </div>
            </div>
          </template>
        </menu-group>
      </template>
    </menu-group>
  </div>
</template>
<script>
import MenuGroup from "../components/MenuGroup.vue";
import Contents from "../components/Contents.vue";
import SlaContents from "../components/SlaContents.vue";
import Solution from "../components/Solution.vue";

export default {
  components: { MenuGroup, Contents, SlaContents, Solution },
  props: ["items", "solutions", "selected", "selectedSolution"],
  methods: {
    isLocationActive(item) {
      return this.selected && item.id == this.selected.id;
    },
    isSolutionActive(solution) {
      return this.selectedSolution && solution.id == this.selectedSolution.id;
    },
    isLocationDocumentsActive(item) {
      const queryParams = new URLSearchParams(window.location.search);

      return (
        this.selected &&
        item.id == this.selected.id &&
        queryParams.get("showDocuments") === "true"
      );
    },
  },
};
</script>
