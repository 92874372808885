<template>
  <support-layout :menuIndex="0">
    <div class="relative flex flex-col flex-grow">
      <div class="flex-1 flex flex-col flex-grow">
        <div class="flex flex-col">
          <span
            class="text-lg text-gray-500 font-bold mx-6 inline-block uppercase hidden"
          >
            {{ $t("pages.newsupportcase.scanheading") }}
          </span>
          <div class="flex hidden">
            <input
              type="text"
              class="w-full outline-none text-gray-500 px-4 py-2"
              v-model="form.solutionId"
            />
            <button @click="qrScannerActive = true" class="pl-2">
              <inline-svg
                :src="require('@/assets/camera.svg')"
                class="fill-current h-8 text-brand7"
              />
            </button>
            <qr-scanner-overlay
              :scanner-active="qrScannerActive"
              :scan-heading="$t('pages.newsupportcase.scanqr')"
              @scan-finished="handleQrScanResult"
              @scan-error="handleScanError"
              @scan-cancelled="() => (qrScannerActive = false)"
            />
          </div>
          <multiselect
            mode="single"
            v-model="selectedSupportType"
            :options="supportTypes"
            :closeOnSelect="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :canClear="false"
            :canDeselect="false"
            :object="true"
            :placeholder="$t('pages.newsupportcase.chooselocation')"
            @select="selectRequestType"
            class="text-prime-green uppercase mb-4 font-black"
            :classes="multiselectClasses"
          />
          <multiselect
            mode="single"
            v-model="selectedLocation"
            :options="formattedLocations"
            :closeOnSelect="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :canClear="false"
            :canDeselect="false"
            :object="true"
            :placeholder="$t('pages.newsupportcase.chooselocation')"
            @select="selectLocation"
            class="text-prime-green uppercase mb-4 font-black"
            :classes="multiselectClasses"
          />
          <multiselect
            mode="single"
            v-model="selectedSolution"
            :options="computedSolutions"
            :closeOnSelect="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :canClear="false"
            :canDeselect="false"
            :object="true"
            :placeholder="$t('pages.newsupportcase.choosesolution')"
            @select="selectSolution"
            class="text-prime-green uppercase mb-4 font-black"
            :classes="multiselectClasses"
          />
        </div>
        <div class="px-6 mb-4 hidden" v-if="currentSla">
          <i18n-t
            :keypath="
              isSlaActive(currentSla)
                ? 'components.sla.statusActive'
                : 'components.sla.statusInactive'
            "
            tag="div"
            class="text-xs text-gray-500 font-bold inline-block mr-2"
          >
            {{ formatDate(currentSla.endsAt) }}
          </i18n-t>
          <inline-svg
            :src="require('@/assets/circle.svg')"
            class="w-4 inline-block fill-current"
            :class="isSlaActive(currentSla) ? 'text-green-600' : 'text-red-600'"
          />
        </div>
        <div
          class="px-6 text-lg text-gray-500 font-semibold flex flex-col hidden"
        >
          <div class="flex flex-col mt-2 space-y-2">
            <span class="whitespace-pre-line">{{
              $t("pages.newsupportcase.beforesubmit")
            }}</span>
            <inertia-link :href="route('home')" class="text-brand7">{{
              $t("pages.newsupportcase.howtoscan")
            }}</inertia-link>
            <inertia-link :href="route('home')" class="text-brand7">{{
              $t("pages.newsupportcase.quicktroubleshooting")
            }}</inertia-link>
          </div>
        </div>
        <div
          class="px-6 text-lg text-gray-500 font-semibold flex flex-col hidden"
        >
          <div class="mt-8 space-y-2">
            <data-line
              :heading="$t('pages.supportcase.createdat')"
              :value="formatDateMultiline(currentDateTime)"
            />
            <data-line
              :heading="$t('pages.supportcase.locationname')"
              :value="
                selectedLocation
                  ? selectedLocation.name
                  : $t('common.placeholders.unknown')
              "
            />
            <div v-if="selectedLocation" class="flex leading-5 space-x-2">
              <span class="break-words min-w-1/2">
                {{ $t("pages.supportcase.locationaddress") }}
              </span>
              <div
                class="flex flex-col min-w-1/2 break-all whitespace-pre-line"
              >
                <span>
                  {{
                    selectedLocation.addressLine1 ||
                    $t("pages.supportcase.location.unknownstreet")
                  }}
                </span>
                <span>{{ selectedLocation.addressLine2 }}</span>
                <span>
                  {{
                    selectedLocation.state ||
                    $t("pages.supportcase.location.unknownregion")
                  }}
                </span>
                <span>
                  {{ selectedLocation.postalCode }}
                  {{ selectedLocation.postalCode && " " }}
                  {{
                    selectedLocation.city ||
                    $t("pages.supportcase.location.unknowncity")
                  }}
                </span>
                <span>
                  {{
                    selectedLocation.country ||
                    $t("pages.supportcase.location.unknowncountry")
                  }}
                </span>
              </div>
            </div>
            <data-line
              :heading="$t('pages.supportcase.solutionname')"
              :value="selectedSolutionName || $t('common.placeholders.unknown')"
            />
            <data-line
              :heading="$t('pages.supportcase.username')"
              :value="auth.user.name"
            />
          </div>
        </div>
        <input
          type="text"
          class="outline-none text-prime-black placeholder-prime-grey-dark border-prime-grey-dark px-4 py-2 w-full rounded mb-4"
          :placeholder="$t('pages.newsupportcase.subjectplaceholder')"
          v-model="form.name"
        />
        <textarea
          v-model="form.message"
          :placeholder="$t('pages.newsupportcase.descriptionplaceholder')"
          rows="3"
          class="outline-none text-prime-black h-40 w-full placeholder-prime-grey-dark border-prime-grey-dark px-4 py-2 resize-none rounded flex-grow"
        />

        <div
          class="flex flex-col sm:flex-row items-start w-full sm:justify-between"
        >
          <div class="flex flex-col items-start text-green-2 w-full sm:w-4/5">
            <file-input
              :files="form.files"
              @files-added="(files) => form.files.push(...files)"
              @files-cleared="() => (form.files = [])"
              class="my-2"
            />
            <div class="flex flex-row w-full flex-wrap">
              <span
                v-for="file in form.files"
                :key="file"
                class="border-2 border-green-2 block flex items-center pr-2 mb-1 mr-1"
              >
                <inline-svg
                  :src="require('@/assets/burger-menu-open.svg')"
                  class="fill-current h-8 text-green-2 inline-block"
                  @click="form.files = form.files.filter((f) => f !== file)"
                />
                {{ file.name }}
              </span>
            </div>
          </div>
          <button
            class="mt-4 py-2 h-10 uppercase text-white w-full base-button sm:w-1/5"
            :disabled="!isFormFilled"
            @click="createCase"
          >
            {{ $t("pages.newsupportcase.create") }}
          </button>
        </div>
      </div>
    </div>
  </support-layout>
</template>
<script>
import DataLine from "@/components/DataLine";
import FileInput from "@/components/FileInput";
import { objectToFormData } from "@/util/formData";
import { isSlaActive } from "@/util/slaHelpers";
import QrScannerOverlay from "../../components/QrScannerOverlay.vue";
import SupportLayout from "../SupportLayout.vue";
import Multiselect from "@vueform/multiselect";
import "@/assets/scss/multiselect-tailwind.scss";
import multiselectClasses from "@/util/multiselectClasses.js";

export default {
  components: {
    DataLine,
    QrScannerOverlay,
    FileInput,
    SupportLayout,
    Multiselect,
  },
  props: {
    auth: Object,
    locations: Array,
    locationId: String,
    solutions: Array,
    solutionId: String,
    locationFirstSla: Object,
    supportCaseId: String,
  },
  data() {
    let supportTypes = [
      {
        id: 0,
        name: this.$t("common.requests.support"),
        route: "support-cases.create",
      },
      {
        id: 1,
        name: this.$t("common.requests.document"),
        route: "document-requests.create",
      },
      {
        id: 2,
        name: this.$t("common.requests.feature"),
        route: "feature-requests.create",
      },
    ];
    return {
      multiselectClasses,
      supportTypes: supportTypes,
      selectedSupportType: supportTypes.find((st) => st.id === 0),
      selectedLocation: this.locations?.find((l) => l.id === this.locationId),
      selectedSolution: this.solutions?.find((s) => s.id === this.solutionId),
      form: this.$inertia.form({
        solutionId: this.solutionId,
        locationId: this.locationId,
        name: null,
        message: null,
        files: [],
      }),
      currentDateTime: new Date(),
      dateTimeTimeout: null,
      dateTimeInterval: null,
      qrScannerActive: false,
    };
  },
  methods: {
    formatDateMultiline(date) {
      return `${date.toLocaleDateString()}\n${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    },
    handleQrScanResult(data) {
      try {
        const scannedUrl = new URL(data);
        if (scannedUrl.searchParams.has("solutionId")) {
          this.form.solutionId = scannedUrl.searchParams.get("solutionId");
        } else {
          throw new Error("Missing solution id from url");
        }
      } catch {
        alert(this.$t("pages.newsupportcase.invalidqr"));
      } finally {
        this.qrScannerActive = false;
      }
    },
    handleScanError(errorName) {
      alert(this.$t(`common.qrScanError.${errorName}`));
    },
    createCase() {
      this.form
        .transform((data) => objectToFormData(data))
        .post(this.route("support-cases.store"));
    },
    isSlaActive,
    formatDate(date) {
      if (!date) return this.$t("common.placeholders.unknown");

      return new Date(date).toLocaleDateString();
    },
    selectRequestType(type) {
      this.$inertia.visit(
        this.route(type.route, {
          locationId: this.form.locationId,
          solutionId: this.form.solutionId,
        }),
        { replace: true }
      );
    },
    selectLocation(location) {
      this.$inertia.visit(
        this.route("support-cases.create", { locationId: location.id }),
        { replace: true }
      );
    },
    selectSolution(solution) {
      this.$inertia.visit(
        this.route("support-cases.create", {
          locationId: this.form.locationId,
          solutionId: solution.id,
        }),
        { replace: true }
      );
    },
  },
  computed: {
    computedSolutions() {
      return [
        ...this.solutions,
        { name: this.$t("common.fields.solution.other"), id: null },
      ];
    },
    selectedLocationName() {
      return this.locations?.find((s) => s.id === this.form.locationId)?.name;
    },
    selectedSolutionName() {
      return this.solutions?.find((s) => s.id === this.form.solutionId)?.name;
    },
    isFormFilled() {
      return this.form.name?.trim() && this.form.message?.trim();
    },
    solutionFirstSla() {
      return this.solutions?.find((s) => s.id === this.form.solutionId)
        ?.slaContent;
    },
    currentSla() {
      if (this.form.solutionId) {
        return this.solutionFirstSla;
      } else {
        return this.locationFirstSla;
      }
    },
    formattedLocations() {
      if (!this.locations) return [];

      return this.locations.map((x) => {
        if (x.locationNumber) x.name = `[${x.locationNumber}] ${x.name}`;

        return x;
      });
    },
  },
  created() {
    const nextMinute = () => {
      let date = new Date();
      date.setMinutes(date.getMinutes() + 1);
      date.setSeconds(0);
      return date;
    };
    let minuteOffset = nextMinute().getTime() - new Date().getTime();

    this.dateTimeTimeout = setTimeout(() => {
      this.dateTimeInterval = setInterval(
        () => (this.currentDateTime = new Date()),
        60000
      );
    }, minuteOffset);
  },
  beforeUnmount() {
    clearTimeout(this.dateTimeTimeout);
    clearInterval(this.dateTimeInterval);
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
