import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3'
import { createI18n } from 'vue-i18n';
import route from "./vendor/ziggy";
import en from './locales/en';
import da from './locales/da';

import './index.css'
import InlineSvg from 'vue-inline-svg';
import VButton from './components/VButton';
import VInput from './components/VInput';


const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    da
  }
})

createInertiaApp({
  resolve: async (name) => {
    const module = require(`./Pages/${name}`);

    return module.default;
  },
  setup({ el, app, props, plugin }) {
    createApp({ render: () => h(app, props) })
      .use(plugin)
      .use(i18n)
      .component('inertia-link', Link)
      .component('InlineSvg', InlineSvg)
      .component('VButton', VButton)
      .component('VInput', VInput)
      .mixin({ methods: { route: route } })
      .mount(el);
  }
});
