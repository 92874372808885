<template>
  <menu-item :class="[bg]" :href="link" :active="isActive" v-bind="$attrs">
    <inline-svg
      v-if="icon"
      :src="icon"
      class="fill-current text-white h-8 mr-2"
    />
    <slot name="text" />
  </menu-item>
  <transition-expand>
    <div v-if="isActive">
      <slot name="content" />
    </div>
  </transition-expand>
</template>
<script>
import TransitionExpand from "@/components/TransitionExpand";
import MenuItem from "@/components/MenuItem";
export default {
  components: { MenuItem, TransitionExpand },
  inheritattrs: false,
  props: {
    bg: String,
    icon: String,
    isActive: Boolean,
    activeLink: String,
    inactiveLink: String,
  },
  computed: {
    link() {
      let link = this.activeLink;

      if (!this.isActive) {
        link = this.inactiveLink;
      }

      return link;
    },
  },
};
</script>
