<template>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <inertia-link
        preserve-state
        :replace="true"
        v-for="form in formats"
        :key="form.value"
        :href="route('executive.export.location', { menu, format: form.value })"
        class="mb-4"
      >
        <v-button
          type="submit"
          :class="{
            'bg-prime-green-dark hover:bg-prime-green-dark':
              format == form.value,
          }"
        >
          <p class="mx-auto truncate ...">{{ form.label }}</p>
        </v-button>
      </inertia-link>
    </transition-group>
  </div>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <inertia-link
        preserve-state
        :replace="true"
        v-for="region in regions"
        :key="region.id"
        :href="
          route('executive.export.location', {
            menu,
            format,
            regionId: region.id,
          })
        "
        class="mb-4"
      >
        <v-button
          type="submit"
          :class="{
            'bg-prime-green-dark hover:bg-prime-green-dark':
              regionId == region.id,
          }"
        >
          <p class="mx-auto truncate ...">
            {{
              region.name === "region.unknown"
                ? $t(`common.placeholders.${region.name}`)
                : region.name
            }}
          </p>
        </v-button>
      </inertia-link>
      <inertia-link
        preserve-state
        :replace="true"
        v-for="count in countries"
        :key="count"
        :href="
          route('executive.export.location', { menu, format, country: count })
        "
        class="mb-4"
      >
        <v-button
          type="submit"
          :class="{
            'bg-prime-green-dark hover:bg-prime-green-dark': country == count,
          }"
        >
          <p class="mx-auto truncate ...">{{ count }}</p>
        </v-button>
      </inertia-link>
    </transition-group>
  </div>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <v-button
        v-if="regionId || country || format == 'all'"
        type="submit"
        class="mb-4"
        @click="download"
        :disabled="downloadLoading"
      >
        <p class="mx-auto truncate ...">
          {{ $t("pages.mylocation.download") }}
        </p>
      </v-button>
      <inertia-link
        v-if="regionId || country || format == 'all'"
        preserve-state
        :replace="true"
        :href="
          route('executive.export.location', {
            menu,
            format,
            regionId,
            country,
            toMail: true,
          })
        "
        class="mb-4"
      >
        <v-button
          type="submit"
          class="mb-4"
          :class="{ 'bg-prime-green-dark hover:bg-prime-green-dark': toMail }"
        >
          <p class="mx-auto truncate ...">
            {{ $t("common.actions.sendToEmail") }}
          </p>
        </v-button>
      </inertia-link>
    </transition-group>
  </div>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <v-button
        v-if="toMail"
        :disabled="form.processing"
        key="sendToSelf"
        class="mb-4"
        type="submit"
        @click="sendToMail(true)"
      >
        <p class="mx-auto truncate ...">
          {{ $t("common.actions.sendToMyEmail") }}
        </p>
      </v-button>
      <div v-if="toMail" key="sendToMailInput" class="mb-4">
        <v-input
          type="email"
          v-model="form.email"
          :placeholder="$t('components.form.enterEmail')"
          :error-message="form.errors.email"
          class="rounded h-14 placeholder-prime_grey_dark px-5"
        />
      </div>
      <v-button
        v-if="toMail"
        :disabled="form.processing"
        key="sendToMail"
        class="mb-4"
        type="submit"
        @click="sendToMail(false)"
      >
        <p class="mx-auto truncate ...">
          {{ $t("pages.forgotPassword.submitButton") }}
        </p>
      </v-button>
    </transition-group>
  </div>
</template>
<script>
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
import { objectToFormData } from "@/util/formData";

export default {
  props: [
    // Shared
    "menu",
    "format",
    "toMail",

    // Location
    "regions",
    "regionId",
    "countries",
    "country",
  ],
  data() {
    return {
      downloadLoading: false,
      form: this.$inertia.form({
        email: "",
      }),
      formats: [
        {
          label: this.$t("common.actions.all"),
          value: "all",
        },
        {
          label: this.$t("common.fields.region"),
          value: "region",
        },
        {
          label: this.$t("pages.mylocation.country"),
          value: "country",
        },
      ],
    };
  },
  methods: {
    sendToMail(toSelf) {
      this.form
        .transform((data) => {
          const transformedData = {
            ...data,
            format: this.format,
            regionId: this.regionId,
            country: this.country,
            toSelf: toSelf + "",
          };
          return objectToFormData(transformedData);
        })
        .post(this.route("executive.export.location.mail"));
    },
    download() {
      this.downloadLoading = true;

      let body = {
        format: this.format,
        regionId: this.regionId,
        country: this.country,
      };

      fetch(this.route("executive.export.location.download"), {
        method: "POST",
        body: objectToFormData(body),
      })
        .then(async (res) => ({
          fileName: this.getFileNameFromHeader(
            res.headers.get("content-disposition")
          ),
          contentType: res.headers.get("content-type"),
          blob: await res.blob(),
        }))
        .then((fileData) => {
          const blob = new Blob([fileData.blob], {
            type: fileData.contentType,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            const blobUrl = URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileData.fileName;
            link.click();

            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 250);
          }
        })
        .finally(() => (this.downloadLoading = false));
    },
    getFileNameFromHeader(header) {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    },
  },
};
</script>
<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
</style>
