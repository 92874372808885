export function pascalToSpaces(text) {
  let formattedText = text
    .replace(/[A-Za-z][A-Z]/g, str => `${str.charAt(0)} ${str.charAt(1).toLowerCase()}`);

  return formattedText;
}

export function spacesToPascal(text) {
  let formattedText = text
    .replace(/ [a-z]/g, str => str.trim().toUpperCase());

  return formattedText;
}