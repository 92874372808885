<template>
  <div class="flex-1 px-4 py-4 bg-gray-200">
    <form
      class="border-b pb-4 border-gray-300"
      @submit.prevent="updatePassword"
    >
      <h1
        class="text-xl text-brand7 font-medium"
        v-t="'pages.accountSettings.updatePassword'"
      />
      <div class="space-y-2 mt-4">
        <div>
          <label
            class="text-gray-500"
            v-t="'pages.accountSettings.currentPassword'"
          />
          <v-input
            type="password"
            v-model="form.currentPassword"
            ref="currentPassword"
            :error-message="form.errors.currentPassword"
          />
        </div>
        <div>
          <label
            class="text-gray-500"
            v-t="'pages.accountSettings.newPassword'"
          />
          <v-input
            type="password"
            v-model="form.password"
            ref="password"
            :error-message="form.errors.password"
          />
        </div>
        <div>
          <label
            class="text-gray-500"
            v-t="'pages.accountSettings.confirmPassword'"
          />
          <v-input
            type="password"
            v-model="form.passwordConfirmation"
            :error-message="form.errors.passwordConfirmation"
          />
        </div>
      </div>
      <div
        class="text-center text-success mt-4 font-bold"
        v-if="form.recentlySuccessful"
        v-t="'pages.accountSettings.passwordUpdated'"
      />
      <v-button class="bg-brand4 mt-4" v-t="'pages.accountSettings.save'" />
    </form>
  </div>
</template>
<script>
import { appLayout } from "../util/layout";
import VButton from "../components/VButton.vue";
import VInput from "../components/VInput.vue";
export default {
  components: { VInput, VButton },
  layout: appLayout({ title: "account settings" }),
  data() {
    return {
      form: this.$inertia.form({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
      }),
    };
  },
  methods: {
    updatePassword() {
      this.form.put(this.route("user-password.update"), {
        preserveScroll: true,
        onSuccess: () => this.form.reset(),
        onError: () => {
          if (this.form.errors.password) {
            this.form.reset("password", "passwordConfirmation");
            this.$refs.password.focus();
          }
          if (this.form.errors.currentPassword) {
            this.form.reset("currentPassword");
            this.$refs.currentPassword.focus();
          }
        },
      });
    },
  },
};
</script>
