import actions from "./actions.json";
import fields from "./fields.json";
import placeholders from "./placeholders.json";
import qrScanError from "./qrScanError.json";
import supportCaseStatuses from "./supportCaseStatuses.json";
import time from "./time.json";
import months from "./months.json";
import requests from "./requests.json";

export default {
  actions,
  fields,
  placeholders,
  qrScanError,
  supportCaseStatuses,
  time,
  months,
  requests,
};