<template>
  <div class="flex-column">
    <div class="flex-row text-green-2">
      <label class="block">
        <input class="hidden" type="file" multiple @change="handleFileInput" />
        <inline-svg
          :src="require('@/assets/plus.svg')"
          class="fill-current h-8 font-black inline-block mr-2"
        />
        {{
          !!files.length
            ? $t("components.form.filesattached", {
                amount: files.length,
              })
            : $t("components.form.attachfiles")
        }}
      </label>
    </div>
    <div v-if="showFiles" class="flex flex-row w-full flex-wrap">
      <span
        v-for="file in files"
        :key="file"
        class="border-2 border-green-2 block flex items-center pr-2 mb-1 mr-1 truncate"
      >
        <inline-svg
          :src="require('@/assets/burger-menu-open.svg')"
          class="fill-current h-8 text-green-2 inline-block"
          @click="removeFile(file)"
        />
        {{ file.name }}
      </span>
    </div>
  </div>
</template>
<script>
import bannedAttachmentFileTypes from "@/util/bannedAttachmentFileTypes";

export default {
  props: {
    files: Array,
    showFiles: Boolean,
  },
  methods: {
    removeFile(file) {
      this.$emit("file-removed", file);
    },
    handleFileInput(event) {
      const selectedFiles = [
        ...(event.target.files || event.dataTransfer.files),
      ];

      if (selectedFiles.length === 0) return;

      let totalSize = selectedFiles.reduce(
        (total, current) => total + current.size,
        0
      );

      const isLargerThan20Mb = totalSize / 1024 / 1024 > 20;

      if (isLargerThan20Mb) {
        alert(this.$t("components.form.files.toolarge"));
        return false;
      }

      let includesBannedFileType = selectedFiles.some((f) =>
        bannedAttachmentFileTypes.some(
          (ft) => ft === f.name.split(".").slice(-1)[0]
        )
      );

      if (includesBannedFileType) {
        alert(this.$t("components.form.files.bannedtype"));
        return false;
      }

      this.$emit("files-added", selectedFiles);
    },
  },
};
</script>
