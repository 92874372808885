<template>
  <div>
    <menu-group
      v-for="(status, statusIndex) in statuses"
      :key="status"
      :bg="statusIndex % 2 === 0 ? 'bg-brand4' : 'bg-brand5'"
      :is-active="selectedStatus === status"
      :active-link="route(indexRoute)"
      :inactive-link="route(indexRoute, { status })"
      :only="['supportCases', 'status']"
    >
      <template #text>
        <div class="pl-12">{{ $t(status) }}</div>
      </template>
      <template #content>
        <search-bar
          v-model="supportCaseSearchText"
          :placeholder="$t('pages.supportcase.searchplaceholder')"
        />
        <div class="divide-y divide-white">
          <div
            v-for="supportCase in filteredSupportCases"
            :key="supportCase.id"
            class="bg-gray-200 text-gray-500"
          >
            <inertia-link :href="route(showRoute, supportCase.id)">
              <div class="p-4">
                <h3 class="text-sm text-brand7 font-bold">
                  {{ supportCase.referenceKey }}
                </h3>
                <h3
                  v-if="locationSolutionText(supportCase) !== ''"
                  class="text-lg font-medium"
                >
                  {{ locationSolutionText(supportCase) }}
                </h3>
                <h3 class="text-lg font-medium">
                  {{ supportCase.name }}
                </h3>
                <h3 class="text-md font-medium">
                  {{ lastActionTimestamp(supportCase) }}
                </h3>
              </div>
            </inertia-link>
          </div>
        </div>
      </template>
    </menu-group>
  </div>
</template>
<script>
import MenuGroup from "../components/MenuGroup.vue";
import SearchBar from "../components/SearchBar.vue";

export default {
  components: { MenuGroup, SearchBar },
  props: ["statuses", "selectedStatus", "items", "indexRoute", "showRoute"],
  data() {
    return {
      supportCaseSearchText: "",
    };
  },
  computed: {
    filteredSupportCases() {
      if (!this.items) return [];

      let searchText = this.supportCaseSearchText.trim().toLowerCase();
      if (!searchText) {
        return this.items;
      }

      return this.items.filter(
        (sc) =>
          sc.referenceKey.includes(searchText) ||
          sc.name.toLowerCase().includes(searchText) ||
          this.lastActionTimestamp(sc).includes(searchText) ||
          sc.location?.name?.toLowerCase().includes(searchText) ||
          sc.solution?.name?.toLowerCase().includes(searchText)
      );
    },
  },
  methods: {
    lastActionTimestamp(supportCase) {
      const lastAction =
        supportCase.lastMessageReceivedAt ||
        supportCase.manualStartedAt ||
        supportCase.createdAt;
      return new Date(lastAction).toLocaleString();
    },
    locationSolutionText(supportCase) {
      let result = "";

      if (supportCase.location) {
        result += supportCase.location.name;
      }

      if (supportCase.solution) {
        result += ` / ${supportCase.solution.name}`;
      }

      return result;
    },
  },
};
</script>
