<template>
  <div class="absolute w-screen h-screen bg-prime-green-dark p-4">
    <div class="flex flex-col items-end">
      <a @click="back" class="cursor-pointer">
        <inline-svg
          class="fill-current text-white h-8"
          :src="require('@/assets/burger-menu-open.svg')"
        />
      </a>
    </div>
    <div class="flex flex-col text-center items-center mx-10">
      <inline-svg
        class="fill-current text-white h-24 w-24"
        :src="require('@/assets/file-document-outline.svg')"
      />
      <span class="inline-block text-xl text-white font-bold uppercase">
        {{ $t("common.actions.export.sendTo") }}
      </span>
      <p class="my-4 inline-block text-xl text-green-2 font-bold break-all">
        {{ recipient }}
      </p>
      <span class="inline-block text-lg text-white font-bold">
        {{ $t("common.actions.export.arrivalTime") }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    recipient: String,
  },
  methods: {
    back() {
      window.history.back();
    },
  },
};
</script>
