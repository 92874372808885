<template>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <inertia-link
        preserve-state
        :replace="true"
        v-for="form in formats"
        :key="form.value"
        :href="route('executive.export.support', { menu, format: form.value })"
        class="mb-4"
      >
        <v-button
          type="submit"
          :class="{
            'bg-prime-green-dark hover:bg-prime-green-dark':
              format == form.value,
          }"
        >
          <p class="mx-auto truncate ...">{{ form.label }}</p>
        </v-button>
      </inertia-link>
    </transition-group>
  </div>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <VueDatePicker
        v-if="format != null"
        class="mb-4"
        @change="customDateRangeSelected"
        v-model="datepickerDate"
        range
        :enableTimePicker="false"
      >
        <template #dp-input="{}">
          <v-button
            :class="{
              'bg-prime-green-dark hover:bg-prime-green-dark': dateIndex == 1,
            }"
          >
            <p class="mx-auto truncate ...">{{ customDateRangeLabel }}</p>
          </v-button>
        </template>
      </VueDatePicker>
      <inertia-link
        preserve-state
        :replace="true"
        v-for="(range, index) in dateRangesList"
        :key="index"
        :href="
          route('executive.export.support', {
            menu,
            format: format,
            dateIndex: range.dateIndex,
          })
        "
        class="mb-4"
      >
        <v-button
          type="submit"
          :class="{
            'bg-prime-green-dark hover:bg-prime-green-dark':
              range.dateIndex == dateIndex,
          }"
        >
          <p class="mx-auto truncate ...">{{ range.label }}</p>
        </v-button>
      </inertia-link>
    </transition-group>
  </div>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <v-button
        v-if="dateIndex > 0"
        type="submit"
        class="mb-4"
        @click="download"
        :disabled="downloadLoading"
      >
        <p class="mx-auto truncate ...">
          {{ $t("pages.mylocation.download") }}
        </p>
      </v-button>
      <inertia-link
        v-if="dateIndex > 0"
        preserve-state
        :replace="true"
        :href="
          route('executive.export.support', {
            menu,
            format,
            dateIndex,
            from,
            to,
            toMail: true,
          })
        "
        class="mb-4"
      >
        <v-button
          type="submit"
          class="mb-4"
          :class="{ 'bg-prime-green-dark hover:bg-prime-green-dark': toMail }"
        >
          <p class="mx-auto truncate ...">
            {{ $t("common.actions.sendToEmail") }}
          </p>
        </v-button>
      </inertia-link>
    </transition-group>
  </div>
  <div class="flex flex-col w-screen sm:w-1/4 px-5">
    <transition-group name="fade">
      <v-button
        v-if="toMail"
        :disabled="form.processing"
        key="sendToSelf"
        class="mb-4"
        type="submit"
        @click="sendToMail(true)"
      >
        <p class="mx-auto truncate ...">
          {{ $t("common.actions.sendToMyEmail") }}
        </p>
      </v-button>
      <div v-if="toMail" key="sendToMailInput" class="mb-4">
        <v-input
          type="email"
          v-model="form.email"
          :placeholder="$t('components.form.enterEmail')"
          :error-message="form.errors.email"
          class="rounded h-14 placeholder-prime_grey_dark px-5"
        />
      </div>
      <v-button
        v-if="toMail"
        :disabled="form.processing"
        key="sendToMail"
        class="mb-4"
        type="submit"
        @click="sendToMail(false)"
      >
        <p class="mx-auto truncate ...">
          {{ $t("pages.forgotPassword.submitButton") }}
        </p>
      </v-button>
    </transition-group>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@/assets/css/datepicker.css";
import { ref } from "vue";
import { objectToFormData } from "@/util/formData";

export default {
  components: { VueDatePicker },
  props: [
    // Shared
    "menu",
    "format",
    "toMail",

    // Support
    "dateIndex",
    "from",
    "to",
  ],
  data() {
    return {
      datepickerDate: ref(),
      downloadLoading: false,
      form: this.$inertia.form({
        email: "",
      }),
      formats: [
        {
          label: this.$t("common.fields.overview"),
          value: "overview",
        },
        {
          label: this.$t("common.fields.list"),
          value: "list",
        },
      ],
      dateRanges: [
        {
          label: this.$t("common.time.thisWeek"),
          dateIndex: 2,
        },
        {
          label: this.$t("common.time.lastWeek"),
          dateIndex: 3,
        },
        {
          label: this.$t("common.time.thisMonth"),
          dateIndex: 4,
        },
        {
          label: this.$t("common.time.lastMonth"),
          dateIndex: 5,
        },
        {
          label: this.$t("common.time.thisYear"),
          dateIndex: 6,
        },
        {
          label: this.$t("common.time.lastYear"),
          dateIndex: 7,
        },
      ],
    };
  },
  computed: {
    dateRangesList() {
      return this.format ? this.dateRanges : [];
    },
    customDateRangeLabel() {
      if (this.dateIndex == 1) {
        var from = DateTime.fromISO(this.from).toLocaleString();
        var to = DateTime.fromISO(this.to).toLocaleString();
        return from + " - " + to;
      }
      return this.$t("common.time.dateRange");
    },
    dateRange() {
      switch (this.dateIndex) {
        case 1:
          return { from: this.from, to: this.to };
        case 2:
          return this.thisWeek();
        case 3:
          return this.lastWeek();
        case 4:
          return this.thisMonth();
        case 5:
          return this.lastMonth();
        case 6:
          return this.thisYear();
        case 7:
          return this.lastYear();
        default:
          return null;
      }
    },
  },
  watch: {
    datepickerDate: function (value) {
      var from = DateTime.fromJSDate(value[0]).startOf("day").toUTC().toISO();
      var to = DateTime.fromJSDate(value[1]).endOf("day").toUTC().toISO();
      this.$inertia.visit(
        this.route("executive.export.support", {
          menu: this.menu,
          format: this.format,
          dateIndex: 1,
          from,
          to,
        }),
        { preserveState: true, replace: true }
      );
    },
  },
  methods: {
    moduleNotActive() {
      alert(this.$t("common.placeholders.module.notActive"));
    },
    sendToMail(toSelf) {
      this.form
        .transform((data) => {
          const transformedData = {
            ...data,
            format: this.format,
            from: this.dateRange.from,
            to: this.dateRange.to,
            offset: DateTime.now().offset,
            toSelf: toSelf + "",
          };
          return objectToFormData(transformedData);
        })
        .post(this.route("executive.export.support.mail"));
    },
    download() {
      this.downloadLoading = true;

      let body = {
        format: this.format,
        from: this.dateRange.from,
        to: this.dateRange.to,
        offset: DateTime.now().offset,
      };

      fetch(this.route("executive.export.support.download"), {
        method: "POST",
        body: objectToFormData(body),
      })
        .then(async (res) => ({
          fileName: this.getFileNameFromHeader(
            res.headers.get("content-disposition")
          ),
          contentType: res.headers.get("content-type"),
          blob: await res.blob(),
        }))
        .then((fileData) => {
          const blob = new Blob([fileData.blob], {
            type: fileData.contentType,
          });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          } else {
            const blobUrl = URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileData.fileName;
            link.click();

            setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
            }, 250);
          }
        })
        .finally(() => (this.downloadLoading = false));
    },
    getFileNameFromHeader(header) {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    },
    thisWeek() {
      var date = DateTime.now();
      var first = date.startOf("week");
      var last = date.endOf("week");
      var from = first.toUTC().toISO();
      var to = last.toUTC().toISO();
      return { from, to };
    },
    lastWeek() {
      var date = DateTime.now().minus({ weeks: 1 });
      var first = date.startOf("week");
      var last = date.endOf("week");
      var from = first.toUTC().toISO();
      var to = last.toUTC().toISO();
      return { from, to };
    },
    thisMonth() {
      var date = DateTime.now();
      var first = date.startOf("month");
      var last = date.endOf("month");
      var from = first.toUTC().toISO();
      var to = last.toUTC().toISO();
      return { from, to };
    },
    lastMonth() {
      var date = DateTime.now().minus({ months: 1 });
      var first = date.startOf("month");
      var last = date.endOf("month");
      var from = first.toUTC().toISO();
      var to = last.toUTC().toISO();
      return { from, to };
    },
    thisYear() {
      var date = DateTime.now();
      var first = date.startOf("year");
      var last = date.endOf("year");
      var from = first.toUTC().toISO();
      var to = last.toUTC().toISO();
      return { from, to };
    },
    lastYear() {
      var date = DateTime.now().minus({ year: 1 });
      var first = date.startOf("year");
      var last = date.endOf("year");
      var from = first.toUTC().toISO();
      var to = last.toUTC().toISO();
      return { from, to };
    },
  },
};
</script>
<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
</style>
