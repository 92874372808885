<template>
  <div class="h-screen w-screen bg-prime-green-dark flex flex-col">
    <div></div>
    <div class="h-screen w-screen fixed flex flex-col sm:items-center">
      <inline-svg
        :src="require('@/assets/logo.svg')"
        class="fill-current text-green-2 w-48 max-w-xs sm:mt-16 sm:mx-16 self-start"
      />
      <div
        class="h-screen flex flex-col justify-between px-5 sm:px-10 max-w-screen-sm sm:w-96"
      >
        <slot class="h-screen flex" />
        <div class="self-start flex text-green-2 tracking-wider w-full py-4">
          <inertia-link
            :href="route('privacy-policy')"
            class="font-bold mr-4 text-sm"
          >
            {{ $t("components.authLayout.yourPrivacy") }}
          </inertia-link>
          <a href="https://lcd-media.com/" target="_blank" class="font-bold mr-4 text-sm">
          {{ $t("components.authLayout.aboutPrivacy") }}
          </a>
          <a href="mailto:hey@lcdprime.com" class="font-bold text-sm">{{
            $t("components.authLayout.contactUs")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { year: new Date().getFullYear() };
  },
  computed: {
    user() {
      return this.$page.props.user;
    },
  },
  mounted() {
    if (this.user) {
      this.$i18n.locale = this.user.preferredLocale;
    }
  },
};
</script>
