const fixedAttributes = {
    masterDataNameId: "649f345f-bb47-47b5-af15-6ef294e72afd",
    masterDataPurchasePriceId: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2",
    masterDataPurchaseDateId: "e2af7a7d-9f2e-4797-8f0a-3630fd4e8ab0",
    masterDataPortfolioId: "88f727c4-f63a-4d3c-b94a-c774f8d2d28c",
    masterDataPortfolioSubcategoryId: "bce46452-7ca2-4b17-b4f0-df8a1fbe71c4",
    masterDataWarrantyEndDateId: "b05907c9-6989-4fcf-9487-fbffbdc55ad9",
    masterDataModelId: "fdb4c45b-6f92-4124-a66b-f010cf050884",
    masterDataSerialNumberId: "d588bda2-ae9c-4062-8d4b-5c23f1c2e47b",
    masterDataManufacturerId: "a3191a90-d7ae-4aeb-8968-c4bf7544602f",
    masterDataWeightId: "21c04037-5381-462e-862b-a91a8c63e1e3",
    masterDataProductionDateId: "92afcab9-233a-455c-9c0e-414053348b51",
    masterDataQuantityId: "231745de-4cfd-4e7e-b554-3abd781d3864",
  };

  export {
    fixedAttributes,
  };