<template>
  <div
    class="bg-gray-200 pr-6 pl-4 pt-4 pb-8 space-y-3 text-gray-500 flex-auto"
  >
    <div>
      <h1 class="text-xl text-brand7 font-medium">
        {{ $t("pages.mylocation.availableContent") }}
      </h1>
      <sla-contents v-if="item.slaContents" :sla-contents="item.slaContents" />
      <contents v-if="item.contents" :contents="item.contents" />
      <contents
        v-if="item.solutionType.contents"
        :contents="item.solutionType.contents"
      />
    </div>
    <div class="mt-6">
      <a
        class="text-white font-bold py-2 uppercase bg-brand7 w-full block text-center"
        :href="route('document-requests.create', { solutionId: item.id })"
      >
        {{ $t("pages.home.requestDocuments") }}
      </a>
    </div>
    <div class="mt-6">
      <a
        class="text-white font-bold py-2 uppercase bg-brand7 w-full block text-center"
        :href="route('support-cases.create', { solutionId: item.id })"
      >
        {{ $t("pages.home.newsupportcase") }}
      </a>
    </div>
  </div>
</template>
<script>
import Contents from "../components/Contents.vue";
import SlaContents from "../components/SlaContents.vue";

export default {
  components: { Contents, SlaContents },
  props: {
    item: Object,
  },
};
</script>
