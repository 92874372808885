<template>
  <div class="flex justify-center flex-col my-auto">
    <h1 class="text-xl text-center font-bold uppercase text-white">
      {{ $t("pages.forgotPasswordConfirmation.title") }}
    </h1>
    <p class="font-semibold mt-4 text-center leading-snug text-white">
      {{ $t("pages.forgotPasswordConfirmation.description") }}
    </p>
    <div class="">
      <inertia-link :href="route('login')">
        <v-button type="submit" class="mt-8">{{
          $t("pages.forgotPasswordConfirmation.backButton")
        }}</v-button></inertia-link
      >
    </div>
  </div>
</template>
<script>
import VButton from "@/components/VButton.vue";
import AuthLayout from "./AuthLayout";

export default {
  components: { VButton },
  layout: AuthLayout,
};
</script>
