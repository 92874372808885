<template>
  <div>
    <!-- menu -->
    <transition name="slide">
      <div
        class="fixed flex flex-col bg-prime-green h-screen w-screen sm:max-w-sm sm:top-0 sm:right-0 z-20 px-5 pt-5"
        v-click-outside="onClickOutside"
        v-if="menuVisible"
      >
        <div class="flex flex-row items-center justify-end pb-5">
          <inertia-link href="/" class="mr-auto">
            <inline-svg
              :src="require('@/assets/logo-trim.svg')"
              class="fill-current h-6 ml-4 pt-1 text-green-2"
            />
          </inertia-link>
          <inline-svg
            @click="toggleMenu"
            :src="require('@/assets/burger-menu-open.svg')"
            class="fill-current text-green-2 mr-1 h-10 cursor-pointer"
          />
        </div>
        <v-menu class="px-4 overflow-y-auto flex-grow" />
      </div>
    </transition>

    <div class="bg-prime-green-dark text-white min-h-screen flex flex-col">
      <div class="flex flex-row items-center justify-end px-5 pt-5">
        <inertia-link href="/" class="mr-auto">
          <inline-svg
            :src="require('@/assets/logo-trim.svg')"
            class="fill-current h-6 ml-4 pt-1 text-green-2"
          />
        </inertia-link>
        <inline-svg
          @click="toggleMenu"
          :src="require('@/assets/burger-menu.svg')"
          class="fill-current text-green-2 mr-1 h-10 cursor-pointer"
        />
      </div>

      <div class="flex-grow flex flex-col justify-center px-5">
        <div class="flex flex-col justify-evenly flex-grow">
          <div class="flex flex-col">
            <img
              :src="
                route('api.files.content', {
                  id: organization.logoFile?.id,
                  filename: organization.logoFile?.originalName,
                })
              "
              class="w-24 h-24 my-4 self-center"
            />
            <h1 class="mt-4 font-bold text-center uppercase text-white">
              {{ $t("pages.home.welcome") }}
            </h1>
            <h1 class="mt-4 font-bold text-center uppercase text-prime-yellow">
              {{ organization.name }}
            </h1>
            <h1 class="mt-4 font-bold text-center uppercase text-white">
              {{ organization.subName }}
            </h1>
          </div>

          <div class="sm:flex-row flex flex-col justify-evenly items-center">
            <inertia-link
              :href="route('executive.locations')"
              class="w-full sm:w-1/5"
            >
              <v-button type="submit" class="my-4">{{
                $t("pages.executive.locations")
              }}</v-button>
            </inertia-link>
            <inertia-link
              :href="route('executive.solutions')"
              class="w-full sm:w-1/5"
            >
              <v-button type="submit" class="my-4">{{
                $t("pages.executive.solutions")
              }}</v-button>
            </inertia-link>

            <v-button
              type="submit"
              @click="
                historyVisit(
                  PageKeys.Support,
                  desktopView
                    ? route('executive.support-cases.map')
                    : route('executive.support-cases.menu')
                )
              "
              class="w-full sm:w-1/5 my-4"
              >{{ $t("pages.executive.supportCases") }}</v-button
            >
          </div>
        </div>
      </div>

      <div
        class="self-center w-full flex text-green-2 py-4 px-5 tracking-wider justify-between max-w-sm"
      >
        <inertia-link
          :href="route('privacy-policy')"
          class="font-bold mr-4 text-sm"
        >
          {{ $t("components.authLayout.yourPrivacy") }}
        </inertia-link>
        <a href="https://lcd-media.com/" target="_blank" class="font-bold mr-4 text-sm">
          {{ $t("components.authLayout.aboutPrivacy") }}
        </a>
        <a href="mailto:hey@lcdprime.com" class="font-bold mr-4 text-sm">{{
          $t("components.authLayout.contactUs")
        }}</a>
        <inertia-link
          method="post"
          :href="route('logout')"
          class="font-bold text-sm"
          as="button"
        >
          {{ $t("components.menu.logout") }}
        </inertia-link>
      </div>
    </div>
  </div>
</template>
<script>
import VMenu from "../components/SlideMenu";
import vClickOutside from "click-outside-vue3";
import { PageKeys, SetHistory } from "@/util/navigationHistory.js";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    VMenu,
  },
  data() {
    return {
      organization: this.$page.props.auth.organization,
      PageKeys,
      menuVisible: false,
      year: new Date().getFullYear(),
      currentDate: new Date().toLocaleDateString().replaceAll("/", "."),
      desktopView: window.innerWidth >= 640,
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    onClickOutside() {
      this.toggleMenu();
    },
    historyVisit(pageKey, route) {
      SetHistory(pageKey, "home");
      this.$inertia.visit(route);
    },
  },
  created() {
    this.$inertia.on("success", () => {
      this.menuVisible = false;
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.desktopView = window.innerWidth >= 640;
    });
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(640px);
}
</style>
