<template>
  <support-layout
    :menuIndex="4"
    :title="$t('components.supportLayout.worldMap')"
  >
    <div class="flex-1">
      <google-map
        ref="mapRef"
        :api-key="apiKey"
        style="height: 70vh"
        class="w-full"
        :styles="theme"
        :zoom="zoomLevel"
        :center="center"
        :restriction="restriction"
        :fullscreen-control="false"
        :pan-control="false"
        :rotate-control="false"
        :zoom-control="false"
        :scale-control="false"
        :street-view-control="false"
        :map-type-control="false"
        @zoom_changed="zoomChanged"
      >
        <GoogleCircle
          v-for="location in markers"
          :key="location.id"
          :options="location"
          @click="() => goToLocationDetails(location.id)"
          @mouseover="() => openInfoWindow(location)"
          @mouseout="() => closeInfoWindow()"
        />
      </google-map>
      <div
        class="text-prime-green text-b3 uppercase py-2 px-2 flex flex-col sm:flex-row items-start sm:space-x-14"
      >
        <div class="flex flex-row justify-center space-x-1.5">
          <div
            class="flex-shrink-0 w-4 h-4 bg-indicator-green rounded-full my-1"
          />
          <div class="font-bold">{{ operationalLocations }}</div>
          <div>{{ $t("pages.supportMap.operationalLocations") }}</div>
        </div>

        <div class="flex flex-row justify-center space-x-1.5">
          <div
            class="flex-shrink-0 w-4 h-4 bg-prime-red-dark rounded-full my-1"
          />
          <div class="font-bold">{{ pendingLocations }}</div>
          <div>{{ $t("pages.supportMap.pendingLocations") }}</div>
        </div>

        <div class="flex flex-row justify-center space-x-1.5">
          <div
            class="flex-shrink-0 w-4 h-4 bg-prime-orange rounded-full my-1"
          />
          <div class="font-bold">{{ thirdPartyDependentLocations }}</div>
          <div>{{ $t("pages.supportMap.thirdPartyDependentLocations") }}</div>
        </div>
      </div>
    </div>
  </support-layout>
</template>
<script>
import {
  defineComponent,
  ref,
  watch,
  onUnmounted,
  computed,
  watchEffect,
} from "vue";
import { GoogleMap, Circle } from "vue3-google-map";
import { Inertia } from "@inertiajs/inertia";
import theme from "../mapTheme";
import route from "@/vendor/ziggy";
import SupportLayout from "./SupportLayout.vue";
import "@/assets/css/gmInfoWindow.css";
import eventBus from "../eventBus";

function useTotalIssues(props) {
  const operationalLocations = ref(0);
  const pendingLocations = ref(0);
  const thirdPartyDependentLocations = ref(0);

  watch(
    () => props.locations,
    (locations) => {
      operationalLocations.value = locations.reduce(
        (accumulator, location) =>
          accumulator +
          (location.latitude && location.longitude && !location.activeCase && !location.thirdPartyDependent
            ? 1
            : 0),
        0
      );

      pendingLocations.value = locations.reduce(
        (accumulator, location) =>
          accumulator +
          (location.latitude && location.longitude && location.activeCase && !location.thirdPartyDependent
            ? 1
            : 0),
        0
      );

      thirdPartyDependentLocations.value = locations.reduce(
        (accumulator, location) =>
          accumulator +
          (location.latitude && location.longitude && location.thirdPartyDependent
            ? 1
            : 0),
        0
      );
    },
    { immediate: true }
  );

  return {
    operationalLocations,
    pendingLocations,
    thirdPartyDependentLocations,
  };
}

function useMarkers(props, zoomLevel) {
  function circleProperties(type, color) {
    // use 2 to the power of current zoom to calculate relative radius.
    // Base of exponent is 2 because relative size should double every time you zoom in
    const relativeRadius = Math.round(600000 / Math.pow(2, zoomLevel.value));

    return function (location) {
      return {
        key: `${location.key}-${type}`,
        city: location.city,
        country: location.country,
        addressLine1: location.addressLine1,
        addressLine2: location.addressLine2,
        id: location.id,
        center: { lat: location.latitude, lng: location.longitude },
        radius: relativeRadius,
        strokeColor: "white",
        strokeWeight: 1,
        fillColor: color,
        fillOpacity: 1,
      };
    };
  }

  const markers = computed(() => {
    return [
      ...props.locations
        .filter((x) => !x.activeCase && !x.thirdPartyDependent)
        .map(circleProperties("operational", "#82CA3F")),
      ...props.locations
        .filter((x) => x.activeCase && !x.thirdPartyDependent)
        .map(circleProperties("pending", "#D8001D")),
      ...props.locations
        .filter((x) => x.thirdPartyDependent)
        .map(circleProperties("thirdParty", "#E66118")),
    ].filter((m) => m.center.lat && m.center.lng);
  });

  return { markers };
}

function useNoRepeatRestriction() {
  return {
    restriction: {
      latLngBounds: {
        east: 179.9,
        west: -180,
        north: 85,
        south: -85,
      },
      strictBounds: true,
    },
  };
}

function useMapCenter(props, mapRef) {
  const center = ref({ lat: 40.736111, lng: 34.473889 });
  const bounds = ref(null);

  const stopWatch = watchEffect(() => {
    const isReady = mapRef.value ? mapRef.value.ready : false;
    if (!isReady) return;
    const api = mapRef.value.api;
    bounds.value = new api.LatLngBounds();

    // if (!props.locations) {
    //   stopWatch();
    //   return;
    // }

    // props.locations?.forEach((x) => {
    //   if (x.latitude && x.longitude) {
    //     bounds.value.extend({ lat: x.latitude, lng: x.longitude });
    //   }
    // })

    // Make Europe the default map area
    bounds.value.extend({ lat: 32.89317906442078, lng: 40.902341085434884 });
    bounds.value.extend({ lat: 63.18036751999279, lng: -20.56058538540087 });

    center.value = bounds.value.getCenter();

    //ensure enough of the map is visible when markers are close to each other
    api.event.addListenerOnce(mapRef.value.map, "bounds_changed", function () {
      this.setZoom(Math.min(5, this.getZoom()));
    });

    mapRef.value.map.fitBounds(bounds.value);

    stopWatch();
  });

  return {
    center,
    bounds,
  };
}

export default defineComponent({
  components: { GoogleMap, GoogleCircle: Circle, SupportLayout },
  props: {
    locations: Array,
  },
  setup(props) {
    const mapRef = ref(null);
    const twentySeconds = 10000;
    const zoomLevel = ref(4);
    const infoWindow = ref(null);
    const shouldRefreshMap = ref(true);

    eventBus.$on("stopMapRefresh", () => {
      shouldRefreshMap.value = false;
  });

    const fetchDataHandler = setInterval(() => {
      if (shouldRefreshMap.value) {
      Inertia.reload({});
      }
    }, twentySeconds);

    const goToLocationDetails = (locationId) => {
      Inertia.get(route("executive.locations.show", { id: locationId }));
    };

    onUnmounted(() => clearInterval(fetchDataHandler));

    const stopWatch = watchEffect(() => {
      const isReady = mapRef.value ? mapRef.value.ready : false;
      if (!isReady) return;
      const api = mapRef.value.api;
      infoWindow.value = new api.InfoWindow({
        content: "hello",
      });
      stopWatch();
    });

    const openInfoWindow = (location) => {
      if (infoWindow.value != null) {
        infoWindow.value.setContent(
          "<p>" +
            (location.city
              ? location.city
              : this.$t("common.placeholders.city.unknown")) +
            " " +
            (location.country
              ? location.country
              : this.$t("common.placeholders.country.unknown")) +
            "</p>" +
            "<p>" +
            (location.addressLine1
              ? location.addressLine1
              : this.$t("common.placeholders.street.unknown")) +
            "</p>" +
            (location.addressLine2
              ? "<p>" + location.addressLine2 + "</p>"
              : "")
        );
        infoWindow.value.setPosition(
          new mapRef.value.api.LatLng(location.center.lat, location.center.lng)
        );
        infoWindow.value.open(mapRef.value);
      }
    };

    const closeInfoWindow = () => {
      if (infoWindow.value != null) {
        infoWindow.value.close();
      }
    };

    return {
      apiKey: process.env.VUE_APP_MAPS_KEY,
      theme,
      mapRef,
      zoomLevel,
      goToLocationDetails,
      openInfoWindow,
      closeInfoWindow,
      shouldRefreshMap,
      zoomChanged() {
        zoomLevel.value = mapRef.value.map.getZoom();
      },
      ...useNoRepeatRestriction(),
      ...useMapCenter(props, mapRef),
      ...useTotalIssues(props),
      ...useMarkers(props, zoomLevel, mapRef),
    };
  },
});
</script>
<style scoped>
.bg-green-marker {
  background-color: #90b115;
}
</style>
