<template>
  <div class="absolute w-screen h-screen bg-prime-green-dark p-4">
    <div class="flex flex-col items-end">
      <inertia-link :href="route('home')">
        <inline-svg
          class="fill-current text-white h-8"
          :src="require('@/assets/burger-menu-open.svg')"
        />
      </inertia-link>
    </div>
    <div class="flex flex-col text-center items-center mx-10">
      <inline-svg
        class="fill-current text-green-2 h-32"
        :src="require('@/assets/support-ticket-submitted.svg')"
      />
      <span class="inline-block text-xl text-white font-bold uppercase">
        {{ $t("pages.newsupportcase.casereceived") }}
      </span>
      <span class="inline-block text-lg text-white font-bold mt-12">
        {{ $t("pages.newsupportcase.thankyou") }}
      </span>
      <i18n-t
        keypath="pages.newsupportcase.teaminvestigating"
        tag="span"
        class="inline-block text-lg text-white mt-12 whitespace-pre-line"
      >
        <template #serviceDeskLink>
          <inertia-link :href="route('home')" class="text-green-2" replace>
            {{ $t("pages.newsupportcase.servicedesk") }}
          </inertia-link>
        </template>
      </i18n-t>
      <i18n-t
        keypath="pages.newsupportcase.navigateexistingcases"
        tag="span"
        class="inline-block text-lg text-white mt-12 whitespace-pre-line"
      >
        <template #mySupportCasesLink>
          <inertia-link
            :href="route('executive.support-cases')"
            class="text-green-2"
            replace
          >
            {{ $t("pages.newsupportcase.existingcases") }}
          </inertia-link>
        </template>
      </i18n-t>
      <!-- <i18n-t
        keypath="pages.newsupportcase.troubleshooting"
        tag="span"
        class="inline-block text-lg text-white mt-12 whitespace-pre-line"
      >
        <template #ourGuidesLink>
          <inertia-link :href="route('home')" class="text-green-2" replace>
            {{ $t("pages.newsupportcase.ourguides") }}
          </inertia-link>
        </template>
      </i18n-t> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    supportCaseId: String,
  },
};
</script>
