<template>
  <app-layout
    :title="title"
    :return-href="{
      path: 'executive.locations.show',
      properties: { id: solution?.locationId, replace: false },
    }"
  >
    <div class="overflow-hidden">
      <div
        class="flex sm:justify-between w-screen-3 sm:w-screen sm:px-5 transform transition-all duration-500 ease-out"
      >
        <div
          class="flex flex-col w-screen sm:w-full max-w-4xl px-6 sm:pr-2 sm:pl-5 sm:mt-4 mt-0"
        >
          <sla-content :sla-content="slaContent"></sla-content>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "../AppLayout.vue";
import SlaContent from "../../components/SlaContent.vue";
export default {
  components: { AppLayout, SlaContent },
  props: ["solution", "slaContent"],
  computed: {
    title() {
      console.log(this.solution);
      return this.solution.solutionTemplate.name ?? this.$t("components.sla.sla");
    },
  },
};
</script>

<style></style>
