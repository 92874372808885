import authLayout from "./authLayout.json";
import footer from "./footer.json";
import form from "./form.json";
import menu from "./menu.json";
import sla from "./sla.json";
import slaContent from "./slaContent.json";
import slideMenu from "./slideMenu.json";
import supportcasemessage from "./supportcasemessage.json";
import supportLayout from "./supportLayout.json";

export default {
  authLayout,
  footer,
  form,
  menu,
  sla,
  slaContent,
  slideMenu,
  supportcasemessage,
  supportLayout
};